<template>
  <div class="header">
    <van-nav-bar
      class="nav-bar "
      :title="title"
      left-arrow
      @click-left="onClickLeft"
    />
  </div>
</template>

<script>
export default {
  props: {
    title: String
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    onClickLeft() {
      this.$back();
    }
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.header {
  height: 46px;
  .nav-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}
</style>
