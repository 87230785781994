<template>
  <div class="w100 h100">
    <van-swipe
      indicator-color="#EEC97E"
      :autoplay="4000"
      loop
      touchable
      class="w100 h100"
    >
      <van-swipe-item v-for="(item, index) in images" :key="index">
        <img class="w100 h100 object-cover" v-lazy="item" />
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
export default {
  props: {
    images: Array
  },
  data() {
    return {};
  },
  created() {
    this.initPage();
  },
  mounted() {},
  methods: {
    initPage() {}
  },
  destroyed() {}
};
</script>
<style lang=""></style>
