<template>
  <div class="textContent">
    <div class="intro-message-box fs28 fw400 fc333">
      <!-- 追加文字 -->
      <van-field
        v-model="introTextAdd"
        placeholder="请填写个人简介信息"
        type="textarea"
        row="1"
        :autosize="{ maxHeight: 140 }"
        style="border: 1px solid #eee"
      />
      <!-- 删除 -->
      <div
        class="message-delete  dfr main-end cross-center"
        @click="messageDeleta"
      >
        <div class="delete-img">
          <img src="../../assets/images/trash.png" alt="" class="w100 h100" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    introText: String,
    index: Number
  },
  data() {
    return {};
  },
  computed: {
    introTextAdd: {
      get() {
        return this.introText;
      },
      set(val) {
        const data = { val, index: this.index };
        this.$emit("changeCate", data);
      }
    }
  },
  methods: {
    //个人简介文本删除事件
    messageDeleta() {
      this.$emit("deleteIntroText");
    }
  }
};
</script>

<style lang="less" scoped>
.intro-message-box {
  width: 100%;
  margin-top: 0.28rem;
  position: relative;
  border-radius: 0.1rem;
  overflow: hidden;
  .message-delete {
    position: absolute;
    width: 100%;
    height: 0.5rem;
    bottom: 0rem;
    background: rgba(0, 0, 0, 0.28);
    .delete-img {
      width: 0.3rem;
      height: 0.3rem;
      margin-right: 0.15rem;
    }
  }
}
</style>
