<template>
  <div class=" safe-area-inset-bottom"></div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
  destroyed() {}
};
</script>
<style lang="less" scoped></style>
