<template>
  <div class="plaza-box dfc cross-center" @click="goFeelingInfo">
    <div v-if="info" class="head">
      <div class="dfr cross-center main-between">
        <div class="dfr" @click.stop="goSidongInfo">
          <!-- 头像 -->
          <div class="portrait-box">
            <img
              v-if="info.user.avatar"
              :src="info.user.avatar"
              class="portrait-image w100 h100 object-cover"
              alt=""
            /><img
              v-else
              src="../../assets/images/default-portrait.png"
              class="portrait-image w100 h100"
              alt=""
            />
            <div
              v-if="info.user.is_auth || info.user.is_major_auth"
              class="badge-box"
            >
              <img
                src="../../assets/images/home-emblem.png"
                class="w100 h100"
                alt=""
              />
            </div>
          </div>

          <!-- 信息 -->
          <div v-if="info.user" class="message-box dfc flex-center cross-start">
            <div class="name-box fs32 fw600 fc333">{{ info.user.name }}</div>
            <div class="fs24 fc999 fw400">{{ info.user.job || "" }}</div>
          </div>
        </div>
        <!-- 时间 -->
        <div class="fs24 fw400 fcb2">
          {{ info.share_datetime }}
        </div>
      </div>
    </div>

    <div class="text-box fs28 fc666 fw400 dfr flex-center">
      {{ info.content }}
    </div>

    <div class="bootm-box dfr main-between cross-center">
      <div class="dfr cross-center" @click.stop="goHostInfo">
        <div class="fs24 fc999 dfr">推荐人</div>
        <div v-if="info.share_user" class="referrer fs24 fc666">
          {{ info.share_user.name }}
        </div>
        <div v-if="info.share_user" class="name-plate-box fcfff">
          <name-plate
            :nameplate="info.share_user.host_user_info.level.name"
          ></name-plate>
        </div>
      </div>

      <div
        v-if="info.meeting && info.meeting.field && info.meeting.field.name"
        class="session fctheme fs24 fw400"
      >
        # {{ info.meeting.field.name }} #
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: Object
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    goFeelingInfo() {
      this.$go("feelingInfo", { id: this.info.id });
    },
    goSidongInfo() {
      let targetPath = "detail";
      if (!this.info.user.type) {
        targetPath = "detailS";
      }
      this.$go(targetPath, { id: this.info.user.id });
    },
    goHostInfo() {
      this.$go("detail", { id: this.info.share_user.id });
    }
  },
  destroyed() {}
};
</script>

<style lang="less" scoped>
.plaza-box {
  width: 6.94rem;
  min-height: 1.58rem;
  background-color: #fff;
  box-shadow: 0 0.04rem 0.4rem 0 rgba(0, 0, 0, 0.05);
  border-radius: 0.12rem;
  margin-top: 0.3rem;
  padding: 0.36rem;
  .head {
    width: 6.22rem;
    .portrait-box {
      width: 0.96rem;
      height: 0.96rem;
      position: relative;
      img {
        object-fit: cover;
      }
      .portrait-image {
        border-radius: 50%;
      }
      .badge-box {
        width: 0.28rem;
        height: 0.28rem;
        bottom: 0;
        right: 0;
        position: absolute;
      }
    }
    .message-box {
      margin-left: 0.2rem;
      .name-box {
        margin-bottom: 0.06rem;
      }
    }
    .time-box {
      margin-top: 0.04rem;
    }
  }
  .text-box {
    width: 6.22rem;
    margin-top: 0.4rem;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
  .bootm-box {
    width: 100%;
    margin-top: 0.4rem;
    position: relative;
    .referrer {
      margin-left: 0.16rem;
    }
    .name-plate-box {
      margin-left: 0.12rem;
    }
  }
}
</style>
