<template>
  <div class="dfr cross-center fs36 fc333">
    <div class="title-left"></div>
    <div class="title fb" :style="`font-size:${fontSize};color:${color};`">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    fontSize: {
      type: String,
      default: ".36rem"
    },
    color: {
      type: String,
      default: "#333333"
    },
    fontWeight: {
      type: String,
      default: "600"
    }
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
  destroyed() {}
};
</script>
<style lang="less" scoped>
.title-left {
  width: 0.06rem;
  height: 0.36rem;
  background: linear-gradient(180deg, #fddc98 0%, #e3b06b 100%);
}

.title {
  margin: 0 0 0 0.16rem;
  transform: translateY(0.02rem);
}
</style>
