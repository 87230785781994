<template>
  <div class="director-list dfc cross-center">
    <div v-if="list.length">
      <!-- 0=>'我创建的' 1=>'我主持的' 2=>'我参与的'  默认0 type -->
      <div v-for="(item, index) in list" :key="index" class="director-item">
        <director-card
          @init="initPage"
          :info="item"
          :id="id"
          :type="type"
        ></director-card>
      </div>
    </div>
    <empty v-else></empty>
    <!-- // type 0=>'我创建的' 1=>'我主持的' 2=>'我参与的'  默认0 -->
    <!-- v-if="(type == '0' && status == 2) || (type == '1' && status == 2)" -->
    <!-- status 2 => '待召开', 3 => '会议中', 4 => '会后反馈', 5 => '已完成' -->

    <div
      v-if="info.status != 5 && (type == 0 || type == 1)"
      class="b-button-box "
    >
      <div @click="goSidongSetting" class="recruiting-buttons dfc flex-center">
        <icy-button height="0.8rem" border-radius="0.44rem"
          >私董管理</icy-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    type: String,
    info: Object
  },
  data() {
    return {
      /**
       * data.list.come_from 0案主指定 1主持人指定
       * data.list.type 1 监督人
       * data.list.is_sign 1已签到
       */
      list: []
    };
  },
  created() {
    this.initPage();
  },
  mounted() {},
  methods: {
    initPage() {
      this.initList();
    },
    goSidongSetting() {
      this.$go("recruitingSiDong", {
        id: this.info.id,
        fieldId: this.info.field_id,
        type: this.type
      });
    },
    async initList() {
      const data = {
        id: this.id
      };
      const resp = await this.$API
        .get("joinMeetingSidongList", data)
        .then(resp => resp);
      if (resp.code == 1000) {
        this.list = resp.data.list;
      }
    }
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.director-list {
  background: #f7f7f7;
  min-height: calc(100vh - 44px - 1rem);
  .director-item {
    margin-top: 0.28rem;
  }
  .director-item:last-child {
    margin-bottom: 0.46rem;
  }
}
.b-button-box {
  height: calc(1.2rem + env(safe-area-inset-bottom));
  height: calc(1.2rem + constant(safe-area-inset-bottom));
  .recruiting-buttons {
    padding: 0.2rem 0.36rem;
    padding-bottom: calc(0.2rem + env(safe-area-inset-bottom));
    padding-bottom: calc(0.2rem + constant(safe-area-inset-bottom));
    width: 7.5rem;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>
