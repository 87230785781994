<template>
  <swiper
    ref="myProductSwiper"
    class="banner-swiper por w100 "
    :options="secondSwiperOption"
  >
    <swiper-slide
      class="banner-swiper-slide h100 w100"
      v-for="(item, index) in infoList"
      :key="index"
    >
      <div class="detail-banner-box">
        <sidong-card :info="item"></sidong-card>
      </div>
    </swiper-slide>
  </swiper>
</template>

<script>
export default {
  props: {
    infoList: Array
  },
  data() {
    return {
      secondSwiperOption: {
        loop: false,
        slidesPerView: 3,
        spaceBetween: 25,
        breakpoints: {
          100: {
            //当屏幕宽度大于等于320
            slidesPerView: 2.2,
            spaceBetween: 0
          }
        }
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true
        // }
      }
    };
  },
  created() {},
  mounted() {},
  methods: {},
  destroyed() {}
};
</script>
<style lang="" scoped></style>
