<template>
  <div class="noticeBox dfr">
    <div class="icon-box por">
      <div class="noticeImageBox fs0 dfr flex-center"></div>
      <img
        class="noticeImage poa"
        src="../../assets/images/home-noticeImage.png"
      />
    </div>

    <div class="dfc main-center fs24">
      <van-notice-bar :scrollable="false" background="#FFFFFF" color="#666666">
        <van-swipe
          vertical
          class="notice-swipe"
          :autoplay="4000"
          :show-indicators="false"
        >
          <van-swipe-item
            style="
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            "
            v-for="(item, index) in article"
            :key="index"
          >
            <a :href="item.link">{{ item.value }}</a>
          </van-swipe-item>
        </van-swipe>
      </van-notice-bar>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 通告栏文本数组
    article: Array,
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
  destroyed() {},
};
</script>
<style lang="less" scoped>
/* 通告栏 */
.noticeBox {
  width: 6.94rem;
  height: 0.8rem;
  margin: 0.4rem auto 0;
  border-radius: 0.4rem;
  background: #ffffff;
  box-shadow: 0px 0.04rem 0.4rem 0px rgba(0, 0, 0, 0.05);
  padding: 0.12rem;
  box-sizing: border-box;
}
.noticeImageBox {
  width: 0.56rem;
  height: 0.56rem;
  background: linear-gradient(180deg, #fffbf2 0%, #e3b06b 100%);
  opacity: 0.21;
  border-radius: 50%;
}
.icon-box {
  width: 0.56rem;
  height: 0.56rem;
}
.noticeImage {
  width: 0.36rem;
  height: 0.36rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
