<template>
  <div class=" bottom"></div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},

  mounted() {},
  methods: {},
  destroyed() {}
};
</script>
<style lang="less" scoped>
.bottom {
  height: 1.2rem;
}
// iphoneX、iphoneXs
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .bottom {
    height: 1.4rem;
  }
}

// iphone Xs Max
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
  .bottom {
    height: 1.4rem;
  }
}

// iphone XR
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  .bottom {
    height: 1.4rem;
  }
}
</style>
