<template>
  <div class="list-wrap">
    <van-list
      v-model="loading"
      :offset="200"
      :finished="finished"
      loading-text=""
      v-if="list.length"
      @load="onLoad"
    >
      <div class="item-box" v-for="item in list" :key="item.id">
        <meeting-item
          :type="type + ''"
          :info="item"
          :status="status + ''"
        ></meeting-item>
      </div>
    </van-list>
    <empty v-else content="暂无数据"></empty>
  </div>
</template>

<script>
export default {
  // 0=>'我创建的' 1=>'我主持的' 2=>'我参与的'  默认0 type
  // / 2 => '待召开', 3 => '会议中', 4 => '会后反馈', 5 => '已完成' status
  props: ["status"],
  data() {
    return {
      loading: false,
      page: 1,
      pageSize: 10,
      total: 0,
      finished: false,
      list: []
    };
  },
  created() {
    this.type = parseInt(this.$route.query.type) || "0" + "";
  },
  activated() {
    this.initPage();
  },
  mounted() {
    this.initPage();
  },

  methods: {
    async initPage() {
      this.page = 1;
      this.list = await this.initList();
      if (this.total < this.pageSize) {
        this.loading = false;
        this.finished = true;
      } else {
        this.loading = false;
        this.finished = false;
      }
    },
    async initList() {
      let list = [];
      const data = {
        page: this.page,
        pageSize: this.pageSize,
        type: this.type,
        status: this.status
      };
      const resp = await this.$API.get("meetingList", data).then(resp => resp);

      if (resp.code == 1000) {
        list = resp.data.list;
        this.total = resp.data.length;
      }
      return list;
    },
    async onLoad() {
      if (!this.finished) {
        this.page++;
        const list = await this.initList();
        if (list.length < this.pageSize) {
          this.list = [...this.list, ...list];
          this.loading = false;
          this.finished = true;
        } else {
          this.list = [...this.list, ...list];
          this.loading = false;
          this.finished = false;
        }
      }
    }
  },

  destroyed() {}
};
</script>
<style lang="less" scoped>
.list-wrap {
  background: #f7f7f7;
  min-height: calc(100vh - 44px);
  padding-bottom: 0.2rem;
  .item-box:last-child {
    padding-bottom: 0.4rem;
  }
}
</style>
