<template>
  <div class="dfr cross-center date-wrap main-between">
    <div class="dfr cross-center">
      <div class="date">{{ currentDate }}</div>
      <div class="week">{{ currentWeek }}</div>
    </div>
    <div class="dfr cross-center">
      <div
        v-for="(item, index) in currentTime"
        :key="index"
        :class="[
          item.value == 0 ? 'gray' : '',
          item.value == 1 ? 'white' : '',
          item.value == 2 ? 'yellow' : '',
          'time f fs24 dfr flex-center'
        ]"
        @click="selectTime(item, index)"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  props: {
    activeIndex: Number, //当前数据激活的index
    listSelectIndex: Number, //激活数据的列表的index
    listIndex: Number, //当前列表的index
    info: Object
  },
  data() {
    return {
      currentDate: "",
      currentWeek: "",
      currentTime: []
    };
  },
  watch: {
    listSelectIndex() {
      this.checkIndex(this.activeIndex);
    },
    activeIndex(_index) {
      this.checkIndex(_index);
    }
  },
  created() {
    const weekMap = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
    const times = ["09:00-12:00", "14:00-17:00", "19:00-21:00"];

    this.currentDate = dayjs(this.info.date).format("MM-DD");
    this.currentWeek = weekMap[dayjs(this.info.date).day()];

    let _list = [];
    // 获取 维护自己档期日期没有的前端三个都关闭  0灰色关闭 1白色开放 2黄色有会议

    this.info.time.forEach((item, index) => {
      let data = {
        value: item,
        name: times[index],
        currentDate: this.info.date
      };
      _list.push(data);
    });

    // if (this.listIndex == this.listSelectIndex) {
    //   _list[this.activeIndex].active = true;
    // }

    this.currentTime = _list;
  },
  methods: {
    // 0灰色关闭 1白色开放 2黄色有会议
    selectTime(item, index) {
      if (item.value == 2) {
        return;
      }

      let message = "设置此日程可用";
      let setStatus = 1;
      let apiName = "addCanMeeting";
      if (item.value == 1) {
        message = "设置此日程不可用";
        setStatus = 0;
        apiName = "deleteCanMeeting";
      }

      this.currentTime.forEach((_item, _index) => {
        if (index == _index) {
          this.$dialog
            .confirm({
              message: message
            })
            .then(async () => {
              this.currentTime[_index].value = setStatus;

              let selectDate = `${dayjs(
                this.currentTime[_index].currentDate
              ).format("YYYY")}-${this.currentDate}`;

              let selectTime = this.currentTime[_index].name;
              let timeTypes = {
                "09:00-12:00": 0,
                "14:00-17:00": 1,
                "19:00-21:00": 2
              };
              let selectTimeType = timeTypes[selectTime];
              const data = {
                date: selectDate,
                type: selectTimeType
              };
              const resp = await this.$API
                .post(apiName, data)
                .then(resp => resp);
              if (resp.code == 1000) {
                this.$toast.success("设置成功");
              }
            });
        }
        this.$set(this.currentTime, _index, _item);
      });
      // this.$emit("selectInfo", {
      //   listSelectIndex: this.listIndex,
      //   activeIndex: index
      // });
    },
    checkIndex(activeIndex) {
      if (this.listIndex == this.listSelectIndex) {
        this.currentTime.forEach((item, index) => {
          item.active = false;
          if (index == activeIndex) {
            item.active = true;
          }
          this.$set(this.currentTime, index, item);
        });
      } else {
        this.currentTime.forEach((item, index) => {
          item.active = false;
          this.$set(this.currentTime, index, item);
        });
      }
    }
  }
};
</script>

<style lang="less" csoped>
.date-wrap {
  margin-bottom: 0.2rem;
}
.date {
  min-width: 0.8rem;
}
.week {
  min-width: 0.7rem;
}
.time {
  width: 1.62rem;
  height: 0.52rem;

  transition: all 0.3s;

  &:last-child {
    margin-right: 0;
  }
  &.gray {
    border: 1px solid #eee;
    color: #ccc;
  }
  &.white {
    color: #666;
  }
  &.yellow {
    color: #cb9025;
    border: 1px solid #cb9025;
  }
}
.f,
.s {
  margin-right: 0.2rem;
}
.van-dialog__confirm {
  color: #cb9025;
}

.van-dialog__confirm:active {
  color: #cb9025;
}
</style>
