<template>
  <div class="m-card fs24">
    <div @click.capture="showPopup">
      <div class="dfr main-between">
        <div class="head dfr">
          <!-- 头像 -->
          <div class="portrait-box">
            <img :src="info.avatar" alt="" class="w100 h100 object-cover" />
            <div class="badge-box">
              <img
                src="../../assets/images/home-emblem.png"
                alt=""
                class="w100 h100"
              />
            </div>
          </div>
          <div class="dfc">
            <div class="dfr cross-center">
              <!-- 姓名 -->
              <div class="name-box">
                <div class="fs32 fw600 fc333">
                  {{ info.name }}
                </div>
              </div>
              <!-- 铭牌 -->
              <name-plate
                isBig
                class="nameplate"
                :nameplate="info.level_name"
              />
            </div>
            <div class="host-number dfr fs24 fw400 fc666">
              主持场数
              <div class="number fs24 fw600 fctheme dfc flex-center">
                {{ info.total_count }}
              </div>
            </div>
          </div>
        </div>
        <!-- 价格 -->
        <div class="price-top dfr main-end fs32 fw600 fctheme">
          {{ info.price }}
        </div>
      </div>
      <!-- 描述 -->
      <div v-if="info.field.length" class="field-list-box dfr flex-wrap">
        <div
          class="field-list fs20 fw400 fc666 dfr flex-center"
          v-for="(item, index) in info.field"
          :key="index"
        >
          {{ item.name }}
        </div>
      </div>
      <!-- 推荐 -->
      <div v-if="isRecommend" class="is-recommend fs24 fc999 dfr main-end">
        推荐 {{ info.recommend_time }}
      </div>
    </div>

    <van-popup
      @clos="close"
      :close-on-click-overlay="true"
      v-model="show"
      position="bottom"
      class="host-info dfc"
    >
      <div class="m-max-wrap h100">
        <div class="m-content h100">
          <!-- 头像 -->
          <div class="info-portrait dfr main-center">
            <img :src="info.avatar" alt="" class="w100 h100 object-cover" />
            <div class="info-badge">
              <img
                src="../../assets/images/home-emblem.png"
                alt=""
                class="w100 h100"
              />
            </div>
          </div>
          <!-- 内容 -->
          <div class="host-content h100">
            <!-- top -->
            <div class="dfr main-between">
              <div @click="close" class="arrow-down">
                <img
                  src="../../assets/images/arrow-down.png"
                  alt=""
                  class="w100 h100"
                />
              </div>
              <div class="info-price fs36 fw600 fctheme">{{ info.price }}</div>
            </div>
            <!-- message -->
            <div class="info-message dfc cross-center">
              <div class="dfr flex-center">
                <div class="fs32 fw600 fc333">
                  {{ info.name }}
                </div>
                <div class="info-nameplate">
                  <name-plate isBig :nameplate="info.level_name" />
                </div>
              </div>
              <div class="info-host-number dfr cross-center fs24 fw400 fc666">
                主持场数
                <div class="info-number fw600 fctheme">
                  {{ info.total_count }}
                </div>
              </div>
            </div>
            <!-- 描述 -->
            <div class="info-field-box dfr flex-wrap main-center">
              <div
                class="info-field-list fs20 fw400 fc666 dfr flex-center"
                v-for="(item, index) in info.field"
                :key="index"
              >
                {{ item.name }}
              </div>
            </div>
            <!-- 时间 TODO-->
            <div v-if="show" class="info-center">
              <div v-for="(item, index) in timeData" :key="index">
                <moderator-time-info
                  @selectInfo="selectInfo"
                  :listIndex="index"
                  :listSelectIndex="currentListSelectIndex"
                  :activeIndex="currentSelectIndex"
                  :info="item"
                />
              </div>
            </div>
            <!-- 按钮 -->
            <div class="info-button dfr flex-center">
              <icy-button @click.native="selectMeetingModerator" height="0.8rem"
                >按TA的时间为准开会</icy-button
              >
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  props: {
    info: Object,
    isRecommend: {
      type: Boolean,
      default: false
    },
    currentTime: String,
    currentSelectDate: String,
    isReplace: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      show: false,
      detail: null,
      // 0：代表没有会议 1：代表有会议
      // 第一个值:代表9-12点 第二个值:代表14-17点 第三个值:代表19-21点
      timeData: [],
      currentSelectIndex: NaN,
      currentListSelectIndex: NaN,
      currentDate: []
    };
  },
  created() {
    //
    //
  },
  mounted() {},
  methods: {
    initPage() {},

    async dateMeeting() {
      const resp = await this.$API
        .get("dateMeeting", { uid: this.info.id })
        .then(resp => resp);
      if (resp.code == 1000) {
        this.currentDate = resp.data;
      }
    },
    getDay() {
      const data = [];
      // 最多显示30天  0不可选  1可选
      for (let i = 0; i <= 30; i++) {
        const _data = {
          date: dayjs()
            .add(i, "day")
            .format("YYYY-MM-DD"),
          time: [0, 0, 0],
          active: false
        };
        data.push(_data);
      }
      data.forEach(item => {
        for (let _key in this.currentDate) {
          if (_key == item.date) {
            item.time = this.currentDate[_key];
          }
        }
      });
      this.timeData = data;
    },
    close() {
      this.show = false;
    },
    async showPopup() {
      if (!this.isRecommend) {
        const data = this.$localStore.get("meetingData");
        data.moderatorInfo = this.info;
        data.moderatorId = this.info.id;
        this.$localStore.set("meetingData", data);
        this.$goReplace("createMeeting", { isReplace: this.isReplace });
        return;
      }
      if (this.show) {
        this.show = false;
        return;
      }

      await this.dateMeeting();
      this.getDay();
      this.show = true;
    },
    selectInfo(data) {
      this.currentSelectIndex = data.activeIndex;
      this.currentListSelectIndex = data.listSelectIndex;
    },
    selectMeetingModerator() {
      const data = this.$localStore.get("meetingData");
      data.moderatorInfo = this.info;
      data.moderatorId = this.info.id;
      data.beDate = this.timeData[this.currentListSelectIndex].date;

      data.currentTimeIndex = this.currentSelectIndex;
      data.dateValue = dayjs(data.beDate).format("YYYY年MM月DD日");
      const times = ["09:00-12:00", "14:00-17:00", "19:00-21:00"];
      data.timeValue = times[this.currentSelectIndex];
      data.currentTime = times[this.currentSelectIndex];
      this.$localStore.set("meetingData", data);
      this.$goReplace("createMeeting", { isReplace: this.isReplace });
      this.show = false;
    }
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.m-card {
  width: 6.94rem;
  min-height: 2.44rem;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 0.04rem 0.4rem 0px rgba(0, 0, 0, 0.05);
  border-radius: 0.12rem;
  margin: 0.28rem auto 0;
  .head {
    margin: 0.36rem 0 0 0.36rem;
    .portrait-box {
      position: relative;
      width: 0.96rem;
      height: 0.96rem;
      img {
        border-radius: 50%;
      }
      .badge-box {
        width: 0.28rem;
        height: 0.28rem;
        position: absolute;
        right: 0;
        bottom: 0;
        img {
          position: absolute;
        }
      }
    }
    .name-box {
      margin-top: 0.02rem;
      margin-left: 0.2rem;
    }
    .host-number {
      margin: 0.12rem 0 0 0.2rem;
      .number {
        margin-left: 0.08rem;
      }
    }

    .nameplate {
      margin-left: 0.16rem;
    }
  }
  .price-top {
    margin-top: 0.36rem;
    margin-right: 0.36rem;
  }
  .field-list-box {
    margin: 0.36rem 0 0.4rem 0.36rem;
    .field-list {
      background: #f6f6f6;
      border-radius: 0.04rem;
      padding: 0.08rem 0.16rem;
      margin-right: 0.12rem;
      margin-top: 0.12rem;
    }
  }
  .host-info {
    height: 11.62rem;
    background: transparent;
    box-sizing: border-box;
    .info-portrait {
      width: 1.6rem;
      height: 1.6rem;
      transform: translateY(0.8rem);
      margin: 0 auto;
      position: relative;
      img {
        border-radius: 50%;
      }
      .info-badge {
        width: 0.3rem;
        height: 0.3rem;
        position: absolute;
        right: 0;
        bottom: 0;
        img {
          position: absolute;
        }
      }
    }
    .m-max-wrap {
      background: transparent;
      box-sizing: border-box;
      overflow: hidden;
      .m-content {
        padding: 0 0 0.8rem;
        box-sizing: border-box;
      }
      .host-content {
        background: #fff;
        border-radius: 0.24rem 0.24rem 0px 0px;
        padding: 0 0.36rem;
        .arrow-down {
          width: 0.32rem;
          height: 0.22rem;
          margin-top: 0.5rem;
        }
        .info-price {
          margin-top: 0.36rem;
        }
        .info-message {
          margin-top: 0.16rem;
          .info-nameplate {
            margin-left: 0.16rem;
          }
        }
        .info-host-number {
          margin-top: 0.12rem;
          .info-number {
            margin-left: 0.08rem;
          }
        }
        .info-field-box {
          margin-top: 0.1rem;
          .info-field-list {
            background: #f6f6f6;
            border-radius: 0.08rem;
            padding: 0.08rem 0.16rem;
            margin-top: 0.1rem;
            margin-right: 0.12rem;
          }
        }
        .info-center {
          margin-top: 0.64rem;
          height: 5.2rem;
          overflow-y: auto;
        }
        .info-button {
          // margin-top: 0.22rem;
          position: fixed;
          left: 0;
          right: 0;
          bottom: 0.2rem;
          margin: 0 0.36rem;
          height: 1rem;
        }
      }
    }
  }
  .is-recommend {
    padding: 0.26rem 0;
    border-top: 0.01rem solid #eee;
    margin: 0.36rem 0.36rem 0;
  }
}
</style>
