<template>
  <div class="dfr cross-center date-wrap main-between">
    <div class="dfr cross-center">
      <div class="date">{{ currentDate }}</div>
      <div class="week">{{ currentWeek }}</div>
    </div>
    <div class="dfr cross-center">
      <div
        v-for="(item, index) in currentTime"
        :key="index"
        :class="[
          item.active ? 'select' : '',
          item.value == 0 ? '' : 'active',
          'time f fs24 dfr flex-center'
        ]"
        @click="selectTime(item, index)"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  props: {
    activeIndex: Number, //当前数据激活的index
    listSelectIndex: Number, //激活数据的列表的index
    listIndex: Number, //当前列表的index
    info: Object,
    noClick: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentDate: "",
      currentWeek: "",
      currentTime: []
    };
  },
  watch: {
    listSelectIndex() {
      this.checkIndex(this.activeIndex);
    },
    activeIndex(_index) {
      this.checkIndex(_index);
    }
  },
  created() {
    const weekMap = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
    const times = ["09:00-12:00", "14:00-17:00", "19:00-21:00"];

    this.currentDate = dayjs(this.info.date).format("MM-DD");
    this.currentWeek = weekMap[dayjs(this.info.date).day()];

    let _list = [];

    this.info.time.forEach((item, index) => {
      let data = {
        active: false,
        value: item,
        name: times[index]
      };
      _list.push(data);
    });

    if (this.listIndex == this.listSelectIndex) {
      if (!isNaN(this.activeIndex)) {
        _list[this.activeIndex].active = true;
      }
    }

    this.currentTime = _list;
  },
  methods: {
    selectTime(item, index) {
      if (this.noClick) {
        return;
      }
      if (item.value == 0) {
        return;
      }
      this.currentTime.forEach((_item, _index) => {
        if (index == _index) {
          _item.active = true;
        } else {
          _item.active = false;
        }
        this.$set(this.currentTime, _index, _item);
      });
      this.$emit("selectInfo", {
        listSelectIndex: this.listIndex,
        activeIndex: index
      });
    },
    checkIndex(activeIndex) {
      if (this.listIndex == this.listSelectIndex) {
        this.currentTime.forEach((item, index) => {
          item.active = false;
          if (index == activeIndex) {
            item.active = true;
          }
          this.$set(this.currentTime, index, item);
        });
      } else {
        this.currentTime.forEach((item, index) => {
          item.active = false;
          this.$set(this.currentTime, index, item);
        });
      }
    }
  }
};
</script>

<style lang="less" csoped>
.date-wrap {
  margin-bottom: 0.2rem;
}
.date {
  min-width: 0.8rem;
}
.week {
  min-width: 0.7rem;
}
.time {
  width: 1.62rem;
  height: 0.52rem;
  border: 1px solid #eee;
  color: #ccc;
  transition: all 0.3s;
  &:last-child {
    margin-right: 0;
  }
  &.active {
    border: 1px solid #eee;
    color: #666;
  }
  &.select {
    color: #cb9025;
    border: 1px solid #cb9025;
  }
}
.f,
.s {
  margin-right: 0.2rem;
}
</style>
