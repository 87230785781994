<template>
  <div v-if="info && meetingInfo && userInfo" class="argument-max-wrap">
    <!-- 0=>'我创建的' 1=>'我主持的' 2=>'我参与的'  默认0 type -->
    <!-- 发起 -->
    <div v-if="type == 0 || type == 1">
      <!-- status 0 => "待付款"  1 => "已取消" 2 => '待召开', 3 => '会议中', 4 => '会后反馈', 5 => '已完成' -->
      <div v-if="info.status == 2"><empty content="暂时不可评判"></empty></div>
      <div v-else>
        <div v-if="argumentInfo" class="dfc create-wrap">
          <!-- 标题 -->
          <div class="argument-title fs28 fb fc666">
            改进评判
          </div>
          <!-- 反馈输入框 -->
          <div class="dfr main-center">
            <van-field
              v-model.trim="argumentValue"
              rows="6"
              :disabled="argumentInfo.status == 2 || !isEdit"
              autosize
              type="textarea"
              placeholder="请填写改进的具体反馈内容"
            />
          </div>
          <!-- 图片上传 -->
          <div class="uploader-box dfr cross-center flex-wrap">
            <div
              v-for="(item, index) in uploadImgs"
              :key="index"
              class="uploadImgs"
            >
              <img
                @click.capture="previewImg(index)"
                class="upload-img w100 h100 object-cover"
                :src="item | addImgFix"
                alt=""
              />
              <div
                v-if="isEdit"
                @click.capture="deleteImgs(index)"
                class="delete box fs0"
              >
                <img
                  class="w100 h100 "
                  src="../../assets/images/delete.png"
                  alt=""
                />
              </div>
            </div>
            <!-- 0待创建评判 1待监督确认 2待用户确认 -->
            <div
              v-if="
                isEdit && (argumentInfo.status == 0 || argumentInfo.status == 1)
              "
              class="uploadImgs "
            >
              <van-uploader
                :max-count="10"
                preview-size="2.12rem"
                multiple
                :upload-icon="uploadIcon"
                :after-read="afterRead"
                @delete="deleteImg"
              />
            </div>
          </div>
        </div>

        <!-- 提交我的改进反馈 -->
        <div v-if="info && info.status != 5">
          <div
            v-if="argumentInfo && argumentInfo.status != 2"
            class="argument-button dfc flex-center"
          >
            <!-- status 0待创建评判 1待监督确认 2待用户确认 -->
            <div v-if="isEdit">
              <icy-button @click.native="submitMyArgument" height="0.8rem">
                <span v-if="type == 1">替案主提交改进反馈</span>
                <span v-else>提交我的改进反馈</span>
              </icy-button>
            </div>
            <div v-else>
              <icy-button @click.native="editMyData" height="0.8rem"
                >修改我的改进反馈</icy-button
              >
            </div>
            <icy-iphone></icy-iphone>
          </div>
          <div v-else class="argument-button dfc flex-center">
            <!-- status 0待创建评判 1待监督确认 2待用户确认 -->
            <div class="and-bottom  w100">
              <div class="dfr main-between cross-center">
                <div class="fs24 fw400 fc666">
                  参会私董对改进的评判
                </div>
                <div class="dfr cross-center">
                  <!-- 同意 -->
                  <div class="dfr cross-center" style="margin-right:.4rem">
                    <div class="fs36 fw600 fctheme">
                      {{ argumentInfo.info.agree_meeting_judge_count || 0 }}
                    </div>
                    <div class="and-no-yes fs28 fw500 fc666">
                      通过
                    </div>
                  </div>
                  <!-- 不同意 -->
                  <div class="and-no-box dfr cross-center">
                    <div class="fs36 fw600 fctheme">
                      {{ argumentInfo.info.disagree_meeting_judge_count || 0 }}
                    </div>
                    <div class="and-no-yes fs28 fw500 fc666">
                      不同意
                    </div>
                  </div>
                </div>
              </div>
              <icy-iphone></icy-iphone>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--   我参与的-->
    <div v-else class="argument-box">
      <div v-if="argumentInfo && argumentInfo.info" class="body-and  dfc">
        <div v-if="argumentInfo.info.user" class="dfr main-between">
          <div class="dfr">
            <!-- 头像 -->
            <div class="and-portrait">
              <img
                :src="argumentInfo.info.user.avatar"
                alt=""
                class="w100 h100 object-cover"
              />
              <div class="and-badge">
                <img
                  src="../../assets/images/home-emblem.png"
                  alt=""
                  class="w100 h100"
                />
              </div>
            </div>
            <!-- 信息 -->
            <div class="and-message dfc">
              <div class="and-name fs32 fw600 fc333">
                {{ argumentInfo.info.user.name }}
              </div>
              <div class="fs24 fw400 fc999">
                <div>{{ argumentInfo.info.user.job }}</div>
              </div>
            </div>
          </div>
          <!-- 时间 -->
          <div class="fs24 fw400 fcb2 dfc main-center">
            {{ argumentInfo.info.user.created_at }} 提交改进
          </div>
        </div>
        <!-- 描述 -->
        <div
          class="and-describe fs28 fw400 fc666"
          :class="[isHidden ? 'hidden' : '']"
        >
          {{ argumentInfo.info.content }}
        </div>
        <!-- 展开/收起 -->
        <div class="and-hidden dfr flex-center">
          <div v-show="argumentInfo.info.content.length > 100 ? true : false">
            <div
              class="isHidden-box fs24 fw400 fcb2 dfr cross-center"
              @click="isHidden = !isHidden"
              v-if="isHidden"
            >
              展开 <van-icon name="arrow-down" class="van-icon" />
            </div>
            <div
              class="isHidden-box fs24 fw400 fcb2 dfr cross-center"
              @click="isHidden = !isHidden"
              v-else
            >
              收起 <van-icon name="arrow-up" class="van-icon" />
            </div>
          </div>
        </div>
        <!-- 图片 -->
        <div class="dfr flex-wrap imgs-preview-wrap">
          <div
            class="and-picture-img"
            v-for="(item, index) in argumentInfo.info.images"
            :key="index"
            @click="previewImg(index)"
          >
            <img
              :src="item | addImgFix"
              alt=""
              class="w100 h100 object-cover"
            />
          </div>
        </div>
        <!-- bottom -->
        <div v-if="info && info.status != 5">
          <!-- 参与 -->
          <div class="and-bottom dfc main-between cross-center">
            <!-- status 0待创建评判 1待监督确认 2待用户确认 -->
            <!-- 监督 等待 评判 -->
            <div v-if="argumentInfo.status == 1" class="w100">
              <!-- 监督 -->
              <div
                v-if="userInfo.id == argumentInfo.super_user_id"
                class="dfr main-between w100"
              >
                <icy-button
                  width="3.24rem"
                  height="0.8rem"
                  border-radius="0.44rem"
                  background="#fff"
                  border="1px solid #CB9025"
                  boxshadow=""
                  @click.native="unAgreeHandle"
                  color="#CB9025"
                  >改进并未完成</icy-button
                >
                <icy-button
                  width="3.24rem"
                  height="0.8rem"
                  border-radius="0.44rem"
                  @click.native="agreeHandle"
                  boxshadow=""
                  color="#7C5A2F"
                  >同意改进完成</icy-button
                >
              </div>
              <!-- 其他 -->
              <div v-else class="dfr fctheme fs24 flex-center watcher">
                <icy-button
                  width="6.78rem"
                  height="0.8rem"
                  border-radius="0.08rem"
                  boxshadow=""
                  color="#7C5A2F"
                  >待监督人评判是否完成改进</icy-button
                >
              </div>
            </div>
            <!-- 监督 已评判 -->
            <div v-if="argumentInfo.status == 2" class="dfr main-between w100">
              <!-- 监督显示的内容 -->
              <div v-if="argumentInfo.info.meeting_judge_result">
                <div class="and-bottom  w100">
                  <div class="dfr main-between cross-center">
                    <div class="fs24 fw400 fc666">
                      参会私董对改进的评判
                    </div>
                    <div class="dfr">
                      <!-- 同意 -->
                      <div class="dfr cross-center" style="margin-right:.4rem">
                        <div class="fs36 fw600 fctheme">
                          {{ argumentInfo.info.agree_meeting_judge_count || 0 }}
                        </div>
                        <div class="and-no-yes fs28 fw500 fc666">
                          通过
                        </div>
                      </div>
                      <!-- 不同意 -->
                      <div class="and-no-box dfr cross-center">
                        <div class="fs36 fw600 fctheme">
                          {{
                            argumentInfo.info.disagree_meeting_judge_count || 0
                          }}
                        </div>
                        <div class="and-no-yes fs28 fw500 fc666">
                          不同意
                        </div>
                      </div>
                    </div>
                  </div>
                  <icy-iphone></icy-iphone>
                </div>
              </div>
              <!-- 非监督显示的内容 -->
              <div v-else class="dfr main-between w100">
                <icy-button
                  width="3.24rem"
                  height="0.8rem"
                  border-radius="0.44rem"
                  background="#fff"
                  border="1px solid #CB9025"
                  boxshadow=""
                  @click.native="unAgreeHandle"
                  color="#CB9025"
                  >不同意改进</icy-button
                >
                <icy-button
                  width="3.24rem"
                  height="0.8rem"
                  border-radius="0.44rem"
                  @click.native="agreeHandle"
                  boxshadow=""
                  color="#7C5A2F"
                  >同意改进</icy-button
                >
              </div>
            </div>
            <icy-iphone></icy-iphone>
          </div>
        </div>
      </div>
      <div v-else>
        <empty></empty>
      </div>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
import { uploadImg } from '../../utils/util';
export default {
  props: {
    type: String,
    id: String,
    meetingInfo: Object,
    userInfo: Object
  },
  data() {
    return {
      isEdit: true,
      argumentInfo: null,
      isHidden: true,
      picture: [],
      /**
       * status 0待创建评判 1待监督确认 2待用户确认
       * super_user_id 监督人id
       * info.agree_meeting_judge_count 同意数
       * data.info.disagree_meeting_judge_count 不同意数
       * data.info.meeting_judge_result 不为null自己已发表是否通过
       */
      argumentValue: "",
      fileList: [],
      uploadImgs: [],
      uploadIcon: require("../../assets/images/meeting.png"),
      info: null, // initInfo 获取
      isType: ""
    };
  },
  created() {
    this.initPage();
  },
  mounted() {},
  methods: {
    deleteImgs(index) {
      this.uploadImgs.splice(index, 1);
    },
    previewImg(index) {
      let images = [];
      this.argumentInfo.info.images.forEach(item => {
        images.push(this.$addImgFix(item));
      });
      ImagePreview({
        images: images,
        startPosition: index
      });
    },
    editMyData() {
      this.isEdit = true;
    },
    initPage() {
      this.initInfo();
      this.initMeetingInfo();
    },
    async initMeetingInfo() {
      const resp = await this.$API
        .get("meetingDetail", { id: this.id })
        .then(resp => resp);
      if (resp.code == 1000) {
        this.info = resp.data;
      }
    },
    async initInfo() {
      const data = {
        meetingId: this.id
      };
      const resp = await this.$API
        .post("argumentDetail", data)
        .then(resp => resp);
      if (resp.code == 1000) {
        this.argumentInfo = resp.data;
        if (resp.data.info) {
          this.argumentValue = resp.data.info.content;
          this.isEdit = false;
          if (resp.data.info.images.length) {
            this.uploadImgs = resp.data.info.images;
            resp.data.info.images.forEach(item => {
              this.fileList.push({ url: item });
            });
          }
        }
      }
    },
    async unAgreeHandle() {
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "提交中..."
      });
      const data = {
        meetingId: this.id,
        type: 1
      };
      const resp = await this.$API
        .post("argumentCheck", data)
        .then(resp => resp);
      if (resp.code == 1000) {
        this.isType = data.type;
        this.initPage();
        this.$bus.$emit("updateMeeting");
        this.$toast.success("已提交");
        setTimeout(() => {
          this.$toast.clear();
        }, 1000);
      } else {
        setTimeout(() => {
          this.$toast.clear();
        }, 1000);
      }
    },
    async agreeHandle() {
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "提交中..."
      });
      const data = {
        meetingId: this.id,
        type: 0
      };
      const resp = await this.$API
        .post("argumentCheck", data)
        .then(resp => resp);
      if (resp.code == 1000) {
        this.isType = data.type;
        this.initPage();
        this.$bus.$emit("updateMeeting");
        this.$toast.success("已提交");
        setTimeout(() => {
          this.$toast.clear();
        }, 1000);
      } else {
        setTimeout(() => {
          this.$toast.clear();
        }, 1000);
      }
    },

    /**
     * 轻提示
     */
    toastFun(msg) {
      this.toast = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true, // 禁用背景点击
        loadingType: "spinner",
        message: msg
      });
    },
    /**
     * 上传到ali oss
     */
    afterRead(file) {
      uploadImg(file, (url) => {
        this.getUploadContent(url)
      })
    },
    deleteImg(file, index) {
      this.uploadImgs.splice(index, 1);
    },
    getUploadContent(url) {
      this.uploadImgs.push(url);
    },
    async submitMyArgument() {
      if (!this.id) {
        this.$toast("未找到此会议");
        this.$goReplace("home");
        return;
      }
      if (!this.argumentValue) {
        this.$toast("请填写改进的具体反馈内容");
        return;
      }

      if (this.argumentInfo.status == 1) {
        if (!this.argumentValue) {
          this.$toast("请填写改进的具体反馈内容");
          return;
        }

        const data = {
          meetingId: this.id,
          content: this.argumentValue,
          images: this.uploadImgs
        };
        const resp = await this.$API
          .post("argumentUpdate", data)
          .then(resp => resp);
        if (resp.code == 1000) {
          this.$toast.success("成功提交");
          this.isEdit = false;
          this.initPage();
          this.$bus.$emit("updateMeeting");
        }
        return;
      }

      const data = {
        meetingId: this.id,
        content: this.argumentValue,
        images: this.uploadImgs
      };
      const resp = await this.$API
        .post("argumentCreate", data)
        .then(resp => resp);
      if (resp.code == 1000) {
        this.$toast.success("成功提交");
      }
      this.isEdit = false;
      this.initPage();
      this.$bus.$emit("updateMeeting");
    }
  },
  destroyed() {
    this.$toast.clear();
  }
};
</script>
<style lang="less" scoped>
.uploadImgs {
  width: 2.12rem;
  height: 2.12rem;
  position: relative;
  margin-right: 0.21rem;
  margin-bottom: 0.21rem;
  box-sizing: border-box;
  overflow: hidden;
  &:nth-child(3n) {
    margin-right: 0;
  }
  .delete {
    position: absolute;
    top: 0;
    right: 0;
    width: 0.26rem;
    height: 0.26rem;
  }
  .uploader-vant {
    position: absolute;
    left: 0;
    top: 0;
  }
  .upload-img {
    border-radius: 0.08rem;
  }
}
.imgs-preview-wrap {
  .and-picture-img {
    margin-right: 0.15rem;
    margin-bottom: 0.15rem;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}
.argument-max-wrap {
  background: #fff;
  padding-bottom: 1.6rem;
  .create-wrap {
    border-top: 0.2rem solid #f7f7f7;
    padding: 0.36rem;
  }
  .argument-button {
    bottom: 0;
    position: fixed;
    left: 0;
    right: 0;
    padding: 0.2rem 0;
    background: #fff;
    .and-bottom {
      background: #fff;
      width: 7.5rem;
      padding: 0.36rem;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      .and-no-yes {
        margin-left: 0.08rem;
        margin-top: 0.02rem;
      }
    }
  }
}
.and-picture-img {
  width: 2.12rem;
  height: 2.12rem;
  border-radius: 0.08rem;
  overflow: hidden;
  img {
    object-fit: cover;
  }
}
.argument-box {
  border-top: 0.2rem solid #f6f6f6;
  padding-top: 0.2rem;
  .argument-title {
    margin: 0.36rem 0 0 0.4rem;
  }
  .argument-input {
    width: 6.78rem;
    height: 2.4rem;
    margin-top: 0.36rem;
    textarea::-webkit-input-placeholder {
      color: #cccccc;
    }
  }
  .uploader-box {
    margin: 0.4rem 0 0 0.36rem;
  }

  .body-and {
    width: 6.7rem;
    margin: 0 0.36rem;
    height: auto;
    .and-portrait {
      width: 0.96rem;
      height: 0.96rem;
      position: relative;
      img {
        border-radius: 50%;
      }
      .and-badge {
        width: 0.28rem;
        height: 0.28rem;
        position: absolute;
        bottom: 0;
        right: 0;
        img {
          position: absolute;
        }
      }
    }
    .and-message {
      margin-left: 0.2rem;
      .and-name {
        margin-top: 0.08rem;
      }
    }
    .and-describe {
      margin-top: 0.4rem;
      word-break: break-word;
    }
    .and-hidden {
      margin-top: 0.2rem;
      .isHidden-box {
        margin-bottom: 0.36rem;
        .van-icon {
          margin-left: 0.12rem;
        }
      }
    }
    .hidden {
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }

    .and-picture-img:nth-child(n + 4) {
      margin-top: 0.15rem;
    }
    .and-bottom {
      background: #fff;
      width: 7.5rem;
      padding: 0.36rem;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      .and-no-yes {
        margin-left: 0.08rem;
        margin-top: 0.02rem;
      }
    }
  }
}
</style>
