<template>
  <div class="bootm-box">
    <div class="bootm-messaage dfr cross-center main-between">
      <div class="dfr cross-center" @click.stop="goHostInfo">
        <div class="fs24 fc999">主持人</div>
        <div class="referrer fs24 fc666">{{ info.host_user.name }}</div>
        <div class="name-plate-box fcfff">
          <name-plate
            :nameplate="info.host_user.host_user_info.level.name"
          ></name-plate>
        </div>
      </div>
      <div class="fs24 fw400 fcb2"># {{ info.field.name }} #</div>
    </div>
    <div
      class="siDong-number dfr cross-center main-between"
      v-if="(type == '0' && status == 2) || (type == '1' && status == 2)"
    >
      <div class="number-box dfr cross-center">
        <div class="fs24 fw400 fcb2">
          现有人数
        </div>
        <div class="number fs28 fw600 fctheme dfc main-start">
          {{ info.meeting_user_count }}
        </div>
      </div>
      <div @click.stop="recruiting">
        <icy-button
          width="1.44rem"
          height="0.48rem"
          font-size="0.24rem"
          border-radius="0.28rem"
          style="padding: 0 0"
          >招募私董</icy-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: Object,
    //  statusMap: ["", "", "待召开", "会议中", "会后反馈", "已完成"],
    status: String,
    // type 0=>'我创建的' 1=>'我主持的' 2=>'我参与的'  默认0
    type: String
  },
  data() {
    return {};
  },
  methods: {
    async recruiting() {
      this.$go("recruitingSiDong", {
        id: this.info.id,
        fieldId: this.info.field_id,
        type: this.type
      });
    },
    goHostInfo() {
      this.$go("detail", { id: this.info.host_user.id });
    }
  }
};
</script>

<style lang="less" scoped>
.bootm-box {
  width: 100%;
  margin-top: 0.4rem;
  position: relative;
  .bootm-messaage {
    margin-bottom: 0.25rem;
    .referrer {
      margin-left: 0.16rem;
    }
    .name-plate-box {
      margin-left: 0.12rem;
    }
  }

  .siDong-number {
    border-top: 0.01rem solid #eee;
    height: 0.88rem;
    .number-box {
      .number {
        margin-left: 0.16rem;
        margin-top: 0.02rem;
      }
    }
  }
}
</style>
