import Vue from "vue";

import Vuex from "vuex";

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    alreadyGoMe: false
  },
  mutations: {
    changeGoMe(state) {
      state.alreadyGoMe = true;
    },
    deleteGoMe(state) {
      state.alreadyGoMe = false;
    }
  }
});
