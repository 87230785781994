<template>
  <div class="intro-img-box dfc flex-center">
    <img :src="introImg" alt="" class="w100 h100" />
    <div class="img-delete-box  dfr main-end cross-center" @click="imgDeleta">
      <div class="delete-img">
        <img src="../../assets/images/trash.png" alt="" class="w100 h100" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    introImg: String
  },
  data() {
    return {};
  },
  methods: {
    imgDeleta() {
      this.$emit("deleteIntroImg");
    }
  }
};
</script>

<style lang="less" scoped>
.intro-img-box {
  width: 100%;
  margin-top: 0.28rem;
  position: relative;
  border-radius: 0.1rem;
  overflow: hidden;
  img {
    object-fit: cover;
  }
  .img-delete-box {
    width: 100%;
    height: 0.5rem;
    bottom: 0rem;
    position: absolute;
    background: rgba(0, 0, 0, 0.28);
    .delete-img {
      width: 0.3rem;
      height: 0.3rem;
      margin-right: 0.15rem;
      position: relative;
      img {
        position: absolute;
      }
    }
  }
}
</style>
