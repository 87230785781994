var isBuildTest = process.argv[process.argv.length - 1];
var routerBase = "/";
if (isBuildTest == "--test") {
  routerBase = "/sidonghui/";
} else {
  routerBase = "/";
}
module.exports = {
  domain: "//gj-api.chieflinkofficer.com",
  appid: "wx9c3707266b65c34e", // 正式版需更改
  isShifangJumpBackWithCodeUrl: false, // 正式版改为false
  routerBase: routerBase,
  showVconsole: false,
  endpoint: "oss-cn-shanghai.aliyuncs.com",
  bucket: "yunpaisidonghui",
  region: "oss-cn-shanghai",
  aliOssUrlPrefix: "http://upload.chieflinkofficer.com/",
  pointRMBRate: 1,
  // 用户直接扫二维码进入小程序 所需要的跳转域名
  appletScanURL: "https://gj.chieflinkofficer.com/QR?url="
};
