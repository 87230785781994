import router from "../router";
import store from "store";
const config = require("../../config");
import API from "./api";
import Vue from "vue";
import { Toast } from "vant";
import { go, isWeiXinBrowser } from "./util";
Vue.use(Toast);
/**
 * 获取url参数
 */
const getParam = name => {
  var reg = new RegExp("([?])" + name + "=([^&]*)(&|$)", "i");
  var r = decodeURIComponent(window.location.hash)
    .substr(1)
    .match(reg);
  if (r != null) return r[2];

  reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  r = decodeURIComponent(window.location.search)
    .substr(1)
    .match(reg);
  if (r != null) return r[2];

  return null;
};
const getParamByURL = (url, paraName) => {
  //paraName 等找参数的名称
  let arrObj = url.split("?");
  if (arrObj.length > 1) {
    let arrPara = arrObj[1].split("&");
    let arr;
    for (let i = 0; i < arrPara.length; i++) {
      arr = arrPara[i].split("=");
      if (arr != null && arr[0] == paraName) {
        return arr[1];
      }
    }
  }
  return "";
};
// 移除param
const removeParam = (key, sourceURL) => {
  var rtn = sourceURL.split("?")[0],
    param,
    params_arr = [],
    queryString = sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
  if (queryString !== "") {
    params_arr = queryString.split("&");
    for (var i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split("=")[0];
      if (param === key) {
        params_arr.splice(i, 1);
      }
    }
    rtn = rtn + "?" + params_arr.join("&");
  }
  return rtn;
};

const setParam = (name, val, url) => {
  if (typeof name !== "string") return false;
  if (!url) url = window.location.href;
  var value = name + "=" + encodeURIComponent(val);
  var regex = new RegExp(name + "=[^&]*");
  var urlArr = url.split("#");
  var result = "";

  if (regex.exec(url)) {
    result = url.replace(regex, value);
  } else {
    result = urlArr[0] + "&" + value + (urlArr[1] || "");
  }
  return result;
};

/**
 * 微信浏览器跳转页面并携带code
 */
const wechatBackWithCode = isScan => {
  // 先解码URL
  let locationHref = decodeURIComponent(location.href);
  // 去除code
  locationHref = removeParam("code", locationHref);
  // 再进行加码
  locationHref = encodeURIComponent(locationHref);

  let url;
  if (isScan) {
    url = `https://open.weixin.qq.com/connect/qrconnect?appid=wxd3a687bcd0e4e1ae&redirect_uri=${locationHref}&response_type=code&scope=snsapi_login#wechat_redirect`;
  } else {
    if (config.isShifangJumpBackWithCodeUrl) {
      url = `https://wechat-agent.shifang.tech/jumpBackWithCode?url=${locationHref}&appid=${config.appid}`;
    } else {
      // 正式网址获取code
      url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${config.appid}&response_type=code&scope=snsapi_userinfo&state=0&redirect_uri=${locationHref}#wechat_redirect`;
    }
  }

  location.href = url;
};

/**
 * 登录处理保存jwt 跳转页面
 */
const loginedSaveJWT = (token, path = "home", query = "") => {
  store.set(`JWT`, token);
  store.set(`isLogin`, true);
  go(path, query);
};

/**
 * 登出处理
 */
const logOut = () => {
  store.set(`isLogin`, false);
  store.remove(`JWT`);
  store.remove(`loginType`);

  go("login");
};

/**
 * 使用微信code登录
 */
const wechatLoginByCode = async (code, isScan, resolve) => {
  // const userId = store.get(`userId`);
  // let user_id = "";
  // if (userId) {
  //   user_id = userId;
  // }
  let type = "";
  if (isScan) {
    type = "scan";
  }

  const resp = await API.post("login", { code, type })
    .then(resp => resp)
    .catch(() => {
      logOut();
    });

  if (resp.code == 1000) {
    loginedSaveJWT(resp.data.token, "wechat");
  }
  resolve();
};

/**
 * 检查是否登陆 是否登录过期
 */
const checkLogin = () => {
  const isLogin = store.get(`isLogin`);

  return new Promise(resolve => {
    if (isLogin) {
      resolve();
    } else {
      const loginType = store.get(`loginType`);

      // loginType: 0 手机 1 微信 2匿名
      if (loginType && loginType == 1) {
        let code = "";
        let isScan = false;
        if (isWeiXinBrowser()) {
          isScan = false;
          code = getParam("code");
        } else {
          isScan = true;
          code = getParamByURL(location.href, "code");
        }

        if (code) {
          wechatLoginByCode(code, isScan, resolve);
        } else {
          wechatBackWithCode(isScan);
        }
      } else {
        location.routerName = router.app.$route.name;
        store.set(`locationInfo`, location);
        logOut("savaHref");
      }
    }
  });
};

/**
 * 微信分享
 */
const wxShare = (config, shareInfo, triggerMenu = () => { }, cb = () => { }) => {
  const wx = window.wx;
  if (wx) {
    wx.config(config);
    if (config) {
      // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后
      wx.ready(function () {
        // 分享给好友
        wx.updateAppMessageShareData({
          title: shareInfo.title, // 分享标题
          desc: shareInfo.desc, // 分享描述
          link: shareInfo.link, // 分享链接
          imgUrl: shareInfo.imgUrl[0], // 分享图标
          trigger: () => {
            triggerMenu();
          },
          complete: () => {
            cb();
          },
          fail: () => { }
        });
        let longTitle = "";

        if (!shareInfo.longTitle) {
          longTitle = shareInfo.title;
        } else {
          longTitle = shareInfo.longTitle;
        }

        // 分享到朋友圈
        wx.updateTimelineShareData({
          title: longTitle, // 分享标题
          link: shareInfo.link, // 分享链接
          imgUrl: shareInfo.imgUrl[0], // 分享图标
          trigger: () => {
            triggerMenu();
          },
          complete: () => {
            cb();
          },
          fail: () => { }
        });
      });
    }
  }
};

/**
 * 默认分享配置
 */

const homeShare = async () => {
  if (isWeiXinBrowser()) {
    let link = location.href.split("#")[0];
    // if (userId) {
    //   link =
    //     location.href.split("#")[0] +
    //     `#/home?userId=${userId}&qynStatus=${qynStatus}`;
    // }
    const defaultShareInfo = {
      title: "加入高见私董会",
      desc: "高见私董会分享",
      longTitle: "高见私董会分享",
      link: link,
      imgUrl: [
        location.origin + "/share-icon1.png",
        "http://web.marketinguniversity.cn/share-icon1.png",
        "http://sidonghui.shifang.tech/share-icon1.png"
      ]
    };
    const resp = await API.post(
      "shareConfig",
      { url: location.href.split("#")[0] },
      false
    )
      .then(resp => resp)
      .catch(err => {
        Toast(err.msg);
      });
    if (resp.code == 1000) {
      wxShare(resp.data.jsConfig, defaultShareInfo);
    }
  }
};

export {
  loginedSaveJWT,
  homeShare,
  getParamByURL,
  getParam,
  checkLogin,
  wxShare,
  wechatBackWithCode,
  logOut,
  setParam
};
