<template>
  <div class="detail-box dfc">
    <div>
      <div class="detail-title dfr main-start cross-center">
        <img :src="icon" alt="" />
        <span class="fs32 fw500 fc333">{{ title }}</span>
      </div>
      <div class="detail-text fs28 fw400 fc666">{{ content }}</div>
      <div class="dfr">
        <div class="detail-desc fs24 fc999 fw400">{{ desc }}</div>
        <div class="detail-scale fs24 fc999 fw400">{{ scale }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["icon", "title", "content", "desc", "scale"],
  data() {
    return {};
  },
  created() {}
};
</script>

<style lang="less" scoped>
.detail-box {
  .detail-title {
    img {
      width: 0.32rem;
      height: 0.32rem;
    }
    span {
      margin-left: 0.2rem;
    }
  }
  .detail-text {
    margin-left: 0.52rem;
    margin-top: 0.2rem;
  }
  .detail-desc {
    margin-left: 0.52rem;
    margin-top: 0.08rem;
  }
  .detail-scale {
    margin-left: 0.2rem;
    margin-top: 0.08rem;
  }
  .detail {
    margin-top: 0.6rem;
  }
}
</style>
