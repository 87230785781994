import router from "../router";
import { Toast } from "vant";
import { v4 as uuidv4 } from "uuid";
import API from "./api";
import config from "../../config";
import OSS from "ali-oss";
import lrz from "lrz";

/**
 *
 * @returns 是否是微信小程序
 */
const isApplet = () => {
  let isApplet = false;
  // window.wx.miniProgram.getEnv(function(res) {
  //   if (res.miniprogram) {
  //     isApplet = true;
  //   }
  // });
  // 在微信小程序中
  if (window.__wxjs_environment === "miniprogram") {
    isApplet = true;
  }
  return isApplet;
};

/**
 * 是否是pc端
 */
const isPC = () => {
  var ua = window.navigator.userAgent,
    agents = [
      "Android",
      "iPhone",
      "SymbianOS",
      "Windows Phone",
      "iPod",
      "iPad"
    ],
    isPC = true;
  for (var i = 0, len = agents.length; i < len; i++) {
    if (ua.indexOf(agents[i]) > 0) {
      isPC = false;
      break;
    }
  }
  return isPC;
};

/**
 * 是否是app
 */
const isApp = () => {
  return navigator.plus;
};

/**
 * ios用户
 */
const isIOSUser = () => {
  return isApp() && window.plus.device.vendor == "Apple";
};

/**
 * ios
 *  点击添加active
 */
const iosAddActive = () => {
  // 点击时添加.active效果
  document.body.addEventListener("touchstart", function(e) {
    var target = e.target;
    if (target.dataset.touch === "true") {
      target.classList.add("active");
    }
  });
  // 移动时判断触点是否在元素范围内
  document.body.addEventListener("touchmove", function(e) {
    var target = e.target,
      rect = target.getBoundingClientRect();
    if (target.dataset.touch === "true") {
      // 移出元素时，取消active状态
      if (
        e.changedTouches[0].pageX < rect.left ||
        e.changedTouches[0].pageX > rect.right ||
        e.changedTouches[0].pageY < rect.top ||
        e.changedTouches[0].pageY > rect.bottom
      ) {
        target.classList.remove("active");
      }
    }
  });
  // 触摸结束或取消时去掉效果
  document.body.addEventListener("touchcancel", function(e) {
    var target = e.target;
    if (target.dataset.touch === "true") {
      target.classList.remove("active");
    }
  });
  document.body.addEventListener("touchend", function(e) {
    var target = e.target;
    if (target.dataset.touch === "true") {
      target.classList.remove("active");
    }
  });
};

/**
 * android 用户
 */
const isAndroid = () => {
  let u = navigator.userAgent;
  return u.indexOf("Android") > -1 || u.indexOf("Adr") > -1;
};

/**
 * 是否是微信浏览器
 */
const isWeiXinBrowser = () => {
  var ua = window.navigator.userAgent.toLowerCase();
  return ua.match(/MicroMessenger/i) == "micromessenger";
};

/**
 * 是否是有齐刘海
 * */
const hasNotchInScreen = () => {
  if (window.plus && window.plus.navigator) {
    return window.plus.navigator.hasNotchInScreen();
  }
};

/**
 * 跳转
 */
const go = (name, query = null, needNewTag = false) => {
  if (isIOSUser()) {
    const routeUrl = router.resolve({
      name,
      query
    });
    location.href = routeUrl.href;
  } else {
    if (needNewTag) {
      const { href } = router.resolve({
        name,
        query
      });
      window.open(href, "_blank");
    } else {
      router.push({
        name,
        query
      });
    }
  }
};

/**
 *跳转但是不记录
 */
const goReplace = (name, query = null) => {
  router.replace({
    name,
    query
  });
};

/**
 * 路由返回
 */
const back = () => {
  let historyLen = window.history.length;

  if (isIOSUser()) {
    var ws = window.plus.webview.currentWebview();
    window.plus.webview.close(ws, "pop-out", 200);
  } else {
    if (isApplet()) {
      if (historyLen <= 1) {
        router.replace({ name: "home" });
      } else {
        router.back();
      }
    } else {
      router.back();
    }
  }
};

/**
 * 防抖
 */
const debounce = (func, delay) => {
  let timer = null;
  return function() {
    const _this = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function() {
      func.apply(_this, args);
    }, delay);
  };
};

/**
 * 监听窗口变化
 */
const windowRize = cb => {
  window.onresize = debounce(cb, 200);
};

/**
 * 判断横竖屏
 * 默认812以上都是竖屏
 */
const isPortrait = () => {
  const innerWidth = window.innerWidth;
  // const innerHeight = window.innerHeight;
  if (innerWidth > 812) {
    return true;
  } else {
    if (window.orientation) {
      if (window.orientation == 0 || window.orientation == 180) {
        return true;
      } else {
        return false;
      }
    } else {
      if (screen.width > screen.height) {
        return false;
      } else {
        return true;
      }
    }
  }
};

/**
 * rem适配
 */
const remHandle = () => {
  const r = document.documentElement;
  let a = r.getBoundingClientRect().width;
  let b = a;
  if (b > 750) {
    b = 375;
  }
  const rem = b / 7.5;
  r.style.fontSize = `${rem}px`;
};

/**
 * title
 */
const title = title => {
  if (isApplet()) {
    document.title = "高见私董会";
  } else {
    document.title = title;
  }
};
const timestampFormat = timestamp => {
  function zeroize(num) {
    return (String(num).length == 1 ? "0" : "") + num;
  }

  var curTimestamp = parseInt(new Date().getTime() / 1000); //当前时间戳
  var timestampDiff = curTimestamp - timestamp; // 参数时间戳与当前时间戳相差秒数

  var curDate = new Date(curTimestamp * 1000); // 当前时间日期对象
  var tmDate = new Date(timestamp * 1000); // 参数时间戳转换成的日期对象

  var Y = tmDate.getFullYear(),
    m = tmDate.getMonth() + 1,
    d = tmDate.getDate();
  var H = tmDate.getHours(),
    i = tmDate.getMinutes();

  if (timestampDiff < 60) {
    // 一分钟以内
    return "刚刚";
  } else if (timestampDiff < 3600) {
    // 一小时前之内
    return Math.floor(timestampDiff / 60) + "分钟前";
  } else if (
    curDate.getFullYear() == Y &&
    curDate.getMonth() + 1 == m &&
    curDate.getDate() == d
  ) {
    return "今天" + zeroize(H) + ":" + zeroize(i);
  } else {
    var newDate = new Date((curTimestamp - 86400) * 1000); // 参数中的时间戳加一天转换成的日期对象
    if (
      newDate.getFullYear() == Y &&
      newDate.getMonth() + 1 == m &&
      newDate.getDate() == d
    ) {
      return "昨天" + zeroize(H) + ":" + zeroize(i);
    } else if (curDate.getFullYear() == Y) {
      return (
        zeroize(m) + "月" + zeroize(d) + "日 " + zeroize(H) + ":" + zeroize(i)
      );
    } else {
      return (
        Y +
        "年" +
        zeroize(m) +
        "月" +
        zeroize(d) +
        "日 " +
        zeroize(H) +
        ":" +
        zeroize(i)
      );
    }
  }
};
const addImgFix = imgUrl => {
  return imgUrl + "?x-oss-process=style/sidonghui-scale";
};

/**
 * 上传文件
 * @param {File} file : File 对象
 * @param {Function} callback : 回调函数
 */
const uploadAliOss = async (file, callback) => {
  console.log('file => ', file)
  const fileSize = file.size; // (单位字节)
  const parallelSize = 1024 * 1024 * 30; // 20M以下不需要用切片上传 (单位字节)
  const uuid = uuidv4();
  const endpoint = config.endpoint;
  const bucket = config.bucket;
  const aliOssUrlPrefix = config.aliOssUrlPrefix;
  const region = config.region;
  let path = config.savePath

  const resp = await API.post("ossToken")
    .then(resp => resp)
    .catch(err => {
      this.$toast(err.msg);
    });

  const ossConfig = {
    accessKeyId: resp.data.AccessKeyId,
    accessKeySecret: resp.data.AccessKeySecret,
    stsToken: resp.data.SecurityToken,
    region,
    endpoint,
    bucket
  };
  const client = new OSS(ossConfig);

  path += `${uuid}`
  const imgUrl = `${aliOssUrlPrefix}${path}`
  console.log('imgUrl => ', imgUrl)

  // 定义切片上传方法
  async function multipartUpload() {
    await client.multipartUpload(path, file, {
      parallel: 4,
      partSize: 1024 * 1024,
      progress: function() {}
    });

    callback(imgUrl);
    file.status = "success";
    file.message = "上传成功";
    Toast('上传成功')
  }

  // 定义小文件上传方法
  async function minFileUpload() {
    await client.put(path, file);
    callback(imgUrl);
    file.status = "success";
    file.message = "上传成功";
    Toast('上传成功')
  }

  // 开始上传
  if (fileSize < parallelSize) {
    minFileUpload();
  } else {
    multipartUpload();
  }
};

/**
 * 上传图片
 * @param {File} file : File 对象
 * @param {Function} callback : 回调函数
 */
const uploadImg = async (file, callback) => {
  const uploadAliHandle = res => {
    Toast("正在读取...");
    const limitSize = 1024 * 1024 * 50; // 50M 限制
    // 单个文件
    const fileSize = file.file.size;
    if (fileSize > limitSize) {
      Toast("文件大小不得超过50Mb");
      return;
    } else {
      uploadAliOss(res.file, callback);
    }
  };
  lrz(file.file)
    .then(res => {
      uploadAliHandle(res);
    })
    .catch(err => {
      Toast.fail(err);
    });
};

export {
  isApplet,
  addImgFix,
  iosAddActive,
  isPC,
  go,
  goReplace,
  back,
  remHandle,
  windowRize,
  title,
  isIOSUser,
  hasNotchInScreen,
  isApp,
  isAndroid,
  isWeiXinBrowser,
  debounce,
  isPortrait,
  timestampFormat,
  uploadAliOss,
  uploadImg,
};
