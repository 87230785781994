<template>
  <div v-if="info" class="meeting-details-big">
    <!-- 打电话功能 参考 个人中心的电话 -->
    <div class="details-list dfc">
      <div class="dfr main-between">
        <div class="dfr" @click="goSidongInfo">
          <!-- 头像 -->
          <div class="avatar-box">
            <img
              v-if="info.user.avatar"
              :src="info.user.avatar"
              alt=""
              class="w100 h100 object-cover"
            /><img
              v-else
              src="../../assets/images/default-portrait.png"
              alt=""
              class="w100 h100 object-cover"
            />
            <div v-if="info.user.is_major_auth" class="emblem-box">
              <img
                src="../../assets/images/home-emblem.png"
                alt=""
                class="w100 h100"
              />
            </div>
          </div>
          <!-- 信息 -->
          <div class="message-box dfc flex1">
            <div class="dfr cross-center">
              <div class="fs32 fw600 fc333 dfr cross-center">
                {{ info.user.name }}

                <!-- 铭牌 -->
                <div class="name-plate-box" v-if="info.type == 1 && type != 1">
                  <name-plate nameplate="监督人" />
                </div>
                <!-- 官 -->
                <div class="officer fs24 fw400 fc999" v-if="type == 1">
                  {{ info.user.job }}
                </div>
              </div>
            </div>
            <div class="job-box fs24 fw400 fc999">
              <span v-if="type != 1">{{ info.user.job }} &nbsp;</span>
              <span v-if="info.user.job && info.user.company && type != 1"
                >|</span
              >
              <span>&nbsp;{{ info.user.company }}</span>
            </div>
          </div>
        </div>
        <!-- 电话按钮 -->
        <div v-if="type == 1" class="dfr cross-center">
          <a :href="`tel:${info.user.phone}`" class="phone-box dfr">
            <div class="phone dfc flex-center">
              <img
                src="../../assets/images/phone.png"
                alt=""
                class="w100 h100"
              />
            </div>
          </a>
        </div>
        <!-- 会议在没有关闭前，案主和主持人在手机端都可以删除（已有会议数据的私董不能删除）和添加招募私董 -->
      </div>
      <!-- {{ info.field }} -->
      <!-- 描述 -->
      <div class="field-list-box dfr flex-wrap">
        <div
          class="field-list fs20 fw400 fc666 dfr flex-center"
          v-for="(item, index) in info.user.field"
          :key="index"
        >
          {{ item.name }}
        </div>
      </div>
      <!-- 0=>'我创建的' 1=>'我主持的' 2=>'我参与的'  默认0 type -->
      <!-- 主持会议 -->
      <!-- Sign -->
      <div v-if="type == 1">
        <div class="bottom-sign dfr cross-center main-between">
          <div
            @click="setSupervisor"
            class="supervisor-box dfr cross-center fs24 fc333"
          >
            <div
              :class="[info.type == 1 ? 'active' : '']"
              class="super-icon-box dfr flex-center fs0"
            >
              <img
                v-if="info.type == 1"
                class="super-icon"
                src="../../assets/images/select-yes.png"
                alt=""
              />
            </div>
            设为监督人
          </div>

          <div
            v-if="info.is_sign == 0"
            class="dfc main-center"
            @click="signShow"
          >
            <icy-button
              width="1.44rem"
              height="0.48rem"
              font-size="0.24rem"
              background="#fff"
              border="1px solid #CB9025"
              color="#cb9025"
              boxshadow=""
              >签到</icy-button
            >
          </div>
          <div v-if="info.is_sign == 1" class="dfc main-center">
            <icy-button
              width="1.44rem"
              height="0.48rem"
              font-size="0.24rem"
              background="#fff"
              border="1px solid #ccc"
              color="#ccc"
              boxshadow=""
              >已签到</icy-button
            >
          </div>
        </div>
      </div>

      <!-- 参与会议 -->
      <div v-if="type == 2">
        <div
          class="participate fs24 fw400 fctheme dfr main-end cross-center"
          v-if="info.good"
          @click="cancelAgree"
        >
          <!-- 已点赞 -->
          <div class="participate-give">
            <img
              src="../../assets/images/y-give.png"
              alt=""
              class="w100 h100"
            />
          </div>
          我对TA印象很好
        </div>
        <!-- 未点赞 -->
        <div
          class="participate fs24 fw400 fc666 dfr main-end cross-center"
          v-else
          @click="agree"
        >
          <div class="participate-give">
            <img
              src="../../assets/images/w-give.png"
              alt=""
              class="w100 h100"
            />
          </div>
          我对TA印象很好
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//  <!-- 0=>'我创建的' 1=>'我主持的' 2=>'我参与的'  默认0 type -->
export default {
  props: {
    info: Object,
    type: String,
    id: String
  },
  data() {
    return {
      checked: false
    };
  },

  created() {},
  mounted() {},
  methods: {
    // 签到
    async signShow() {
      const data = {
        meetingId: this.id,
        userId: this.info.user.id
      };
      const resp = await this.$API.post("sidongSign", data).then(resp => resp);
      if (resp.code == 1000) {
        this.$toast.success("签到成功");
        this.info.is_sign = "1";
      }
    },
    // 点赞
    isGive() {
      // this.info.is_sign = 1;
    },
    // 设为监督人
    async setSupervisor() {
      if (this.info.type == 1) {
        return;
      }
      const data = {
        meetingId: this.id,
        userId: this.info.user.id
      };

      const resp = await this.$API
        .post("addSupervisor", data)
        .then(resp => resp);
      if (resp.code == 1000) {
        this.$toast.success("设置成功");
        this.$emit("init");
      }
    },
    // 对TA印象很好
    async agree() {
      if (this.info.good) {
        return;
      }
      const data = {
        meetingId: this.id,
        userId: this.info.user.id
      };

      const resp = await this.$API.post("agreeSidong", data).then(resp => resp);
      if (resp.code == 1000) {
        this.$toast.success("设置成功");
        this.$emit("init");
      }
    },
    async cancelAgree() {
      const data = {
        meetingId: this.id,
        userId: this.info.user.id
      };

      const resp = await this.$API
        .post("cancelAgreeSidong", data)
        .then(resp => resp);
      if (resp.code == 1000) {
        this.$toast.success("取消成功");
        this.$emit("init");
      }
    },
    // go私董详情页
    goSidongInfo() {
      this.$go("detailS", { id: this.info.user.id });
    }
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.super-icon-box {
  width: 0.36rem;
  height: 0.36rem;
  border: 1px solid #e4e4e4;
  border-radius: 50%;
  margin-right: 0.12rem;
  transition: all 0.3s;
  &.active {
    border: 1px solid #000;
    background: #000;
  }
  .super-icon {
    width: 0.2rem;
  }
}
.meeting-details-big {
  height: 100%;
  .details-list {
    width: 6.94rem;
    background: #ffffff;
    border-radius: 0.12rem;
    .avatar-box {
      width: 0.96rem;
      height: 0.96rem;
      position: relative;
      margin: 0.36rem 0 0 0.36rem;
      img {
        border-radius: 50%;
        object-fit: cover;
      }
      .emblem-box {
        width: 0.28rem;
        height: 0.28rem;
        position: absolute;
        bottom: 0;
        right: 0;
        img {
          position: absolute;
          object-fit: cover;
        }
      }
    }
    .message-box {
      margin: 0.38rem 0 0 0.2rem;
      .job-box {
        margin-top: 0.12rem;
      }
    }
    .name-plate-box {
      margin: 0 0 0 0.16rem;
    }
    .officer {
      margin-left: 0.16rem;
    }
    .phone-box {
      width: 0.64rem;
      height: 0.64rem;
      background-color: #fef8ee;
      border-radius: 50%;
      margin-right: 0.36rem;
      .phone {
        width: 0.2594rem;
        height: 0.32rem;
        margin-top: 0.16rem;
        margin-left: 0.19rem;
      }
    }
    .field-list-box {
      margin: 0.21rem 0 0.36rem 0.36rem;
      .field-list {
        background: #f6f6f6;
        border-radius: 0.08rem;
        padding: 0.08rem 0.16rem;
        margin-right: 0.12rem;
        margin-top: 0.15rem;
      }
    }
    .bottom-sign {
      border-top: 0.01rem solid #eee;
      height: 0.88rem;
      width: 6.22rem;
      margin: 0 0.36rem;
    }
    .participate {
      height: 0.88rem;
      border-top: 0.01rem solid #eee;
      width: 6.22rem;
      margin: 0 0.36rem;
      .participate-give {
        width: 0.32rem;
        height: 0.32rem;
        margin-right: 0.12rem;
      }
    }
  }
}
</style>
