import axios from "axios";
import store from "store";

const config = require("../../config");
import { go } from "../utils/util";
import { logOut } from "../utils/logon";
import { Toast } from "vant";
const apiMap = {
  tel: "/api/home/phone",
  needGoAppletPay: "/api/meeting/jump_pay",
  shareConfig: "/api/share/config",
  hostRegister: "/api/user/register",
  bindWechat: "/api/user/bindWx",
  flowRecordList: "/api/flow/list",
  version: "/api/home/version",
  shareAddMeeting: "/api/meeting/directoryUserShareAdd",
  moderatorRecommend: "/api/hostUser/recommendList",
  sidongAdd: "/api/meeting/directoryUserAdd",
  sidongSign: "/api/meeting/directoryUserSign",
  addSupervisor: "/api/meeting/directoryUserSet",
  helpList: "/api/meeting/helpList",
  argumentDetail: "/api/meeting/judge",
  argumentCheck: "/api/meeting/judgeConfirm",
  argumentUpdate: "/api/meeting/judgeUpdate",
  argumentCreate: "/api/meeting/judgeCreate",
  shareFeeling: "/api/meeting/impressionShare",
  confirmHelp: "/api/meeting/helpConfirm",
  joinFeelingList: "/api/meeting/impressionList",
  addFeelingContent: "/api/meeting/impressionAdd",
  addHelpContent: "/api/meeting/helpAdd",
  helpEdit: "/api/meeting/helpEdit",
  agreeSidong: "/api/meeting/directoryUserGood",
  cancelAgreeSidong: "/api/meeting/directoryUserGoodDelete",
  joinMeetingSidongList: "/api/meeting/directoryUserList",
  meetingDetail: "/api/meeting/detail",
  shareMeetingDetail: "/api/meeting/shareDetail",
  withdrawal: "/api/withdrawal/create",
  withdrawalHistoryList: "/api/withdrawal/list",
  ossToken: "/api/oss/config",
  meetingList: "/api/meeting/list",
  charge: "/api/user/recharge",
  createMeeting: "/api/meeting/create",
  appletCreateMeeting: "/api/meeting/mini_program_create",
  sidongList: "/api/directoryUser/list",
  sidongDetail: "/api/directoryUser/detail", // get  {id}
  moderatorList: "/api/hostUser/list",
  moderatorDetail: "/api/hostUser/detail",
  homeInfo: "/api/home/index",
  sendCode: "/api/sendCaptcha",
  levelList: "/api/level/list",
  telLogin: "/api/user/login",
  squareList: "/api/square/list",
  squareRecommon: "/api/square/myList",
  squareDetail: "/api/square/detail",
  fieldList: "/api/field/list",
  userInfo: "/api/user/info",
  dateMeeting: "/api/hostUser/getDateMeeting",
  myDateMeeting: "/api/user/getDateMeeting",
  addCanMeeting: "/api/hostUser/addHold",
  deleteCanMeeting: "/api/hostUser/deleteHold",
  userEdit: "/api/user/edit",
  industry: "/api/industry/list",
  scale: "/api/scale/list",
  deleteSiDong: "/api/meeting/directoryUserDelete",
  meetingEdit: "/api/meeting/edit",
  meetingEnd: "/api/meeting/end",
  sidongCircle: "/api/user/my_circle",
  getSingleSidongPrice: "/api/meeting/getDirectoryUserExpense",
  resourcesList: "/api/directoryUser/major_list",
  resourcesDetail: "/api/directoryUser/major_detail",
  industry_list: "/api/directoryUser/industry_list",
  sendMajor: "/api/directoryUser/send_major_sms",
  // 私董圈人数统计
  circleNum: "/api/users/circle_num",
  // 场地
  siteList: "/api/meeting/meeting_space_list",
  siteDetail: "/api/meeting_space/detail",
  homeSpaceList: "/api/meeting/space_list", //首页
  // 默认主持人
  default: "/api/hold/default",
  // 付款
  sendPay: "/api/meeting/send_pay"
};

/**
 * 后台数据返回statusCodeMap
 */
const statusCodeMap = {
  OK: 1000,
  JwtError: new Set([2004, 2005, 2006, 2007, 2008])
};

/**
 * 发起axios请求
 * @param {*string} url 请求地址
 * @param {*staring} methods 请求方法
 * @param {*object} data 携带的数据
 * @param {*string} JWT header添加的 jwt
 */
const _axios = (url, methods, data, JWT = "") => {
  // 处理请求方式
  let _data = "";
  let _params = data;
  if (methods !== "get") {
    _data = data;
    _params = "";
  }

  // 判断是否需要token
  let httpHeaders = "";

  if (JWT) {
    httpHeaders = { Authorization: "bearer " + JWT };
  }

  return axios({
    url,
    method: methods,
    data: _data,
    params: _params,
    responseType: "json",
    timeout: 10000,
    headers: httpHeaders
  });
};

/**
 * JWT失效的处理
 */

const JwtErrorHandle = async resp => {
  if (resp && resp.data && resp.data.msg) {
    Toast(resp.data.msg);
  }
  // 个人信息页面也有退出登录 需要区分
  logOut();
};

/**
 * 处理请求
 * @param {*string} apiKey
 * @param {*string} methods
 * @param {*object} data
 * @param {*boolean} needJWT
 * @param {*fn} resolve
 * @param {*fn} reject
 */
const handelRequest = (apiKey, methods, data, needJWT, resolve, reject) => {
  // 请求方法
  const request = (JWT = "") => {
    const url = `${config.domain}${apiMap[apiKey]}`;
    _axios(url, methods, data, JWT)
      .then(async resp => {
        // 如果返回的code为1000 将数据返回
        if (resp.data.code == statusCodeMap.OK) {
          resolve(resp.data);
          // JWT错误处理
        } else if (statusCodeMap.JwtError.has(resp.data.code)) {
          JwtErrorHandle(resp);
        } else {
          reject(resp.data);
          Toast(resp.data.msg);
        }
      })
      .catch(err => {
        const errInfo = {
          msg: err
        };
        reject(errInfo);
      });
  };

  // 定义JWT
  let JWT = store.get(`JWT`);

  // 判断是否存在apikey
  if (!apiMap[apiKey]) {
    Toast(`请求接口未添加---apiKey：${apiKey}`);
    const err = {
      msg: `请求接口未添加---apiKey：${apiKey}`
    };
    reject(err);
    return;
  }

  // 判断是否需要JWT
  if (needJWT) {
    // 是否有JWT
    if (JWT) {
      request(JWT);
    } else {
      logOut();
      go("login");
    }
  } else {
    request();
  }
};

const API = {
  beforeRequest: () => {},
  get: (apiKey, data = "", needJWT = true) => {
    return new Promise((resolve, reject) => {
      handelRequest(apiKey, "get", data, needJWT, resolve, reject);
    });
  },
  post: (apiKey, data = "", needJWT = true) => {
    return new Promise((resolve, reject) => {
      handelRequest(apiKey, "post", data, needJWT, resolve, reject);
    });
  },
  afterRequest: () => {}
};
export default API;
