<template>
  <div class="back-home-wrap" :style="{ background: background }">
    <div @click="goHome" class="back-home dfr flex-center">
      <img
        src="../../assets/images/home-active.png"
        alt=""
        class="back-home-icon"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    background: String
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    goHome() {
      this.$goReplace("home");
    }
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.back-home-wrap {
  height: 1rem;
}
.back-home {
  position: fixed;
  bottom: 1.6rem;
  right: 0.3rem;
  width: 1rem;
  height: 1rem;
  background: #fff;
  z-index: 99;
  box-shadow: 0 0 0.1rem 0 rgba(0, 0, 0, 0.12);
  border-radius: 50%;
}
.back-home-icon {
  width: 0.672rem;
  height: 0.48rem;
}
</style>
