import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VConsole from "vconsole";
import { windowRize, remHandle } from "./utils/util";
const config = require("../config");
Vue.config.productionTip = false;

import store from "./store/vuex";
// 引入样式
import "./assets/less/index.less";

// 引入vant组件
import "./libs/vantRegist";
import "./libs/swiper";
import "./libs/bmap";
import { Checkbox, CheckboxGroup, Popup } from "vant";
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Popup);

// 引入全局方法
import "./utils/prototypes";

// 全局Vue过滤器
import * as filterObj from "./utils/vueFilter";
Object.keys(filterObj).forEach(key => Vue.filter(key, filterObj[key]));

// 引入全局组件注册
import componentRegister from "./utils/componentsImport";

// 在开发环境中显示移动端调试
if (config.showVconsole) {
  new VConsole();
}
new Vue({
  componentRegister,
  router,
  store,
  render: h => h(App),
  created() {
    remHandle();
    windowRize(() => {
      remHandle();
    });
  }
}).$mount("#app");
