import Vue from "vue";
import Router from "vue-router";
// 首页
const home = resolve => {
  import("../views/home.vue").then(module => {
    resolve(module);
  });
}; // 登录
const login = resolve => {
  import("../views/login.vue").then(module => {
    resolve(module);
  });
};
// 广场
const square = resolve => {
  import("../views/square.vue").then(module => {
    resolve(module);
  });
}; // point
const point = resolve => {
  import("../views/point.vue").then(module => {
    resolve(module);
  });
};
// 我的
const me = resolve => {
  import("../views/me.vue").then(module => {
    resolve(module);
  });
}; // order
const order = resolve => {
  import("../views/order.vue").then(module => {
    resolve(module);
  });
};
// 我的
const createMeeting = resolve => {
  import("../views/createMeeting.vue").then(module => {
    resolve(module);
  });
};
// 资源
const resources = resolve => {
  import("../views/resources.vue").then(module => {
    resolve(module);
  });
};
// 资源详情
const resourcesDetail = resolve => {
  import("../views/resourcesDetail.vue").then(module => {
    resolve(module);
  });
};
// 主持人列表
const hosts = resolve => {
  import("../views/hosts.vue").then(module => {
    resolve(module);
  });
};
// 私董列表
const directors = resolve => {
  import("../views/directors.vue").then(module => {
    resolve(module);
  });
};

// addHelp
const addHelp = resolve => {
  import("../views/addHelp.vue").then(module => {
    resolve(module);
  });
}; // createFeeling
const createFeeling = resolve => {
  import("../views/createFeeling.vue").then(module => {
    resolve(module);
  });
};
// 我的会议
const myMeeting = resolve => {
  import("../views/myMeeting.vue").then(module => {
    resolve(module);
  });
}; // editHelp
const editHelp = resolve => {
  import("../views/editHelp.vue").then(module => {
    resolve(module);
  });
};
// 会议详情
const meetingDetail = resolve => {
  import("../views/meetingDetail.vue").then(module => {
    resolve(module);
  });
}; // withdrawal
const withdrawal = resolve => {
  import("../views/money/withdrawal.vue").then(module => {
    resolve(module);
  });
}; // withdrawalHistory
const withdrawalHistory = resolve => {
  import("../views/money/withdrawalHistory.vue").then(module => {
    resolve(module);
  });
};
// 编辑会议内容
const editorMeeting = resolve => {
  import("../views/editorMeeting.vue").then(module => {
    resolve(module);
  });
}; // charge
const charge = resolve => {
  import("../views/money/charge.vue").then(module => {
    resolve(module);
  });
};
// 主持人详情页
const detail = resolve => {
  import("../views/detail.vue").then(module => {
    resolve(module);
  });
};
// 私董详情页
const detailS = resolve => {
  import("../views/detailS.vue").then(module => {
    resolve(module);
  });
};
// 添加私董
const addSiDong = resolve => {
  import("../views/addSiDong.vue").then(module => {
    resolve(module);
  });
};
// 招募私董页面
const recruitingSiDong = resolve => {
  import("../views/recruitingSiDong.vue").then(module => {
    resolve(module);
  });
}; // hostRegister
const hostRegister = resolve => {
  import("../views/hostRegister.vue").then(module => {
    resolve(module);
  });
};
// 选择主持人
const moderator = resolve => {
  import("../views/moderator.vue").then(module => {
    resolve(module);
  });
};
// 广场详情页
const feelingInfo = resolve => {
  import("../components/squareInfo/feelingInfo.vue").then(module => {
    resolve(module);
  });
};
// 完善个人信息页面
const perfect = resolve => {
  import("../views/perfect.vue").then(module => {
    resolve(module);
  });
};
// 参会成功页面
const succeed = resolve => {
  import("../views/succeed.vue").then(module => {
    resolve(module);
  });
};
// 创建会议成功页面
const successCreate = resolve => {
  import("../views/successCreate.vue").then(module => {
    resolve(module);
  });
};
// 被邀请人页面
const invite = resolve => {
  import("../views/invite.vue").then(module => {
    resolve(module);
  });
};
// 主持人设置日程页面
const everyday = resolve => {
  import("../views/everyday.vue").then(module => {
    resolve(module);
  });
};
// 推荐场所
const siteList = resolve => {
  import("../views/siteList.vue").then(module => {
    resolve(module);
  });
};
// 推荐场所详情
const siteDetail = resolve => {
  import("../views/siteDetail.vue").then(module => {
    resolve(module);
  });
};
// 我的私董圈
const sidongRing = resolve => {
  import("../views/sidongRing.vue").then(module => {
    resolve(module);
  });
};
// 代发会议详情
const meetingPaid = resolve => {
  import("../views/meetingPaid.vue").then(module => {
    resolve(module);
  });
};
Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/home"
    },
    {
      path: "/home",
      name: "home",
      // meta: {
      //   keepAlive: true
      // },
      component: home
    },
    {
      path: "/login",
      name: "login",
      component: login
    },
    {
      path: "/hostRegister",
      name: "hostRegister",
      component: hostRegister
    },
    {
      path: "/charge",
      name: "charge",
      component: charge
    },
    {
      path: "/resources",
      name: "resources",
      meta: {
        keepAlive: true
      },
      component: resources
    },
    {
      path: "/resourcesDetail",
      name: "resourcesDetail",
      component: resourcesDetail
    },
    {
      path: "/addHelp",
      name: "addHelp",
      component: addHelp
    },
    {
      path: "/editHelp",
      name: "editHelp",
      component: editHelp
    },
    {
      path: "/createFeeling",
      name: "createFeeling",
      component: createFeeling
    },
    {
      path: "/withdrawal",
      name: "withdrawal",
      component: withdrawal
    },
    {
      path: "/withdrawalHistory",
      name: "withdrawalHistory",
      component: withdrawalHistory
    },
    {
      path: "/moderator",
      name: "moderator",
      component: moderator
    },
    {
      path: "/order",
      name: "order",
      component: order
    },
    {
      path: "/successCreate",
      name: "successCreate",
      component: successCreate
    },
    {
      path: "/createMeeting",
      name: "createMeeting",
      component: createMeeting
    },
    {
      path: "/square",
      name: "square",
      meta: {
        keepAlive: true
      },
      component: square
    },
    {
      path: "/point",
      name: "point",
      component: point
    },
    {
      path: "/myMeeting",
      name: "myMeeting",
      meta: {
        keepAlive: true
      },
      component: myMeeting
    },
    {
      path: "/directors",
      name: "directors",
      meta: {
        keepAlive: true
      },
      component: directors
    },
    {
      path: "/hosts",
      name: "hosts",
      meta: {
        keepAlive: true
      },
      component: hosts
    },
    {
      path: "/meetingDetail",
      name: "meetingDetail",
      component: meetingDetail
    },
    {
      path: "/editorMeeting",
      name: "editorMeeting",
      component: editorMeeting
    },
    {
      path: "/me",
      name: "me",
      component: me
    },
    {
      path: "/detail",
      name: "detail",
      component: detail
    },
    {
      path: "/detailS",
      name: "detailS",
      component: detailS
    },
    {
      path: "/addSiDong",
      name: "addSiDong",
      component: addSiDong
    },
    {
      path: "/recruitingSiDong",
      name: "recruitingSiDong",
      component: recruitingSiDong
    },
    {
      path: "/feelingInfo",
      name: "feelingInfo",
      component: feelingInfo
    },
    {
      path: "/perfect",
      name: "perfect",
      component: perfect
    },
    {
      path: "/succeed",
      name: "succeed",
      component: succeed
    },
    {
      path: "/invite",
      name: "invite",
      component: invite
    },
    {
      path: "/everyday",
      name: "everyday",
      component: everyday
    },
    {
      path: "/siteList",
      meta: {
        keepAlive: true
      },
      name: "siteList",
      component: siteList
    },
    {
      path: "/siteDetail",
      name: "siteDetail",
      component: siteDetail
    },
    {
      path: "/sidongRing",
      name: "sidongRing",
      meta: {
        keepAlive: true
      },
      component: sidongRing
    },
    {
      path: "/meetingPaid",
      name: "meetingPaid",
      component: meetingPaid
    }
  ]
});

// 解决router 3.0 bug
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
