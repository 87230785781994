<template>
  <div v-if="currentInfo" class="help-feeling dfc por">
    <div class="dfr main-between">
      <div class="dfr main-start">
        <!-- 头像 -->
        <div class="avatar-box">
          <img
            v-if="currentInfo.user.avatar"
            :src="currentInfo.user.avatar"
            alt=""
            class="w100 h100 object-cover"
          />
          <img
            v-else
            src="../../../assets/images/default-portrait.png"
            alt=""
            class="w100 h100 object-cover"
          />
          <div class="emblem-box" v-if="currentInfo.user.is_major_auth">
            <img
              src="../../../assets/images/home-emblem.png"
              alt=""
              class="w100 h100"
            />
          </div>
        </div>
        <!-- 信息 -->
        <div class="message-box">
          <div class="fs32 fw600 fc333">{{ currentInfo.user.name }}</div>
          <div class="job-box fs24 fw400 fc999">
            {{ currentInfo.user.job }}
          </div>
        </div>
      </div>
      <!-- 插槽 -->
      <div class="slot-box dfc flex-center">
        <slot></slot>
      </div>
    </div>

    <!-- 描述 -->
    <div class="dfc flex-center">
      <div
        class="describe-box fs28 fw400 fc666 dfr "
        :class="[isHidden ? 'hidden' : '']"
      >
        {{ currentInfo.content }}
      </div>
    </div>
    <!-- 0=>'我创建的' 1=>'我主持的' 2=>'我参与的'  默认0 type -->
    <!-- 分享/展开/收起 -->
    <div class="dfr cross-center main-between w100">
      <div class="dfr cross-center">
        <!-- 分享到广场 -->
        <div
          @click="shareFeeling"
          v-if="type == 1 && !currentInfo.share_user_id"
        >
          <div class="fs24 fw400 fc666 dfr share-img-box">
            <div class="share-img">
              <img
                src="../../../assets/images/share.png"
                alt=""
                class="h100 w100"
              />
            </div>
            分享到广场
          </div>
        </div>
        <!--
        want_become_driven:1 想成为案主
        want_become_host:1   想成为主持人
       -->
        <div class="fs26 fctheme dfr cross-center iswant-box" v-if="type == 1">
          <div v-if="currentInfo.want_become_driven == 1">
            想当案主！
          </div>
          <div v-if="currentInfo.want_become_host == 1">
            想成为主持人！
          </div>
        </div>
      </div>

      <!-- 展开/收起 -->
      <div
        v-show="currentInfo.content.length > 110 ? true : false"
        style="margin-right: 0.36rem;"
      >
        <div
          class="isHidden-box fs24 fw400 fcb2 dfr cross-center"
          @click.capture="isHidden = !isHidden"
          v-if="isHidden"
        >
          展开 <van-icon name="arrow-down" class="van-icon" />
        </div>
        <div
          class="isHidden-box fs24 fw400 fcb2 dfr cross-center"
          @click.capture="isHidden = !isHidden"
          v-else
        >
          收起 <van-icon name="arrow-up" class="van-icon" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    meetingId: String,
    info: Object,
    type: String,
    userInfo: Object
  },
  data() {
    return {
      isHidden: true,
      currentInfo: null
    };
  },
  watch: {
    info(_info) {
      this.currentInfo = _info;
    }
  },
  created() {
    this.initPage();
  },
  mounted() {},
  methods: {
    initPage() {
      this.currentInfo = this.info;
    },

    async shareFeeling() {
      // this.$bus.$emit("updateMeeting");
      if (this.info.share_user_id) {
        return;
      }
      const data = {
        id: this.info.id
      };
      const resp = await this.$API
        .post("shareFeeling", data)
        .then(resp => resp);
      if (resp.code == 1000) {
        this.$bus.$emit("updateFeeling");
        this.$toast.success("分享成功");
      }
    }
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.editor-img-box {
  position: absolute;
  width: 0.28rem;
  height: 0.28rem;
  right: 0.2rem;
  top: 0.2rem;
}
.help-feeling {
  width: 6.94rem;
  background: #ffffff;
  border-radius: 0.12rem;
  margin-top: 0.28rem;
  .avatar-box {
    width: 0.96rem;
    height: 0.96rem;
    position: relative;
    margin: 0.36rem 0 0 0.36rem;
    img {
      border-radius: 50%;
    }
    .emblem-box {
      width: 0.28rem;
      height: 0.28rem;
      position: absolute;
      bottom: 0;
      right: 0;
      img {
        position: absolute;
      }
    }
  }
  .message-box {
    margin: 0.42rem 0 0 0.2rem;
    .job-box {
      margin-top: 0.12rem;
    }
  }
  .slot-box {
    margin-top: 0.36rem;
  }
  .describe-box {
    width: 6.22rem;
    margin: 0.26rem 0;
    word-break: break-word;
  }
  .hidden {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
  .share-img-box {
    margin-left: 0.36rem;
    .share-img {
      width: 0.32rem;
      height: 0.32rem;
      margin-bottom: 0.36rem;
      margin-right: 0.12rem;
    }
  }

  .van-icon {
    margin-left: 0.12rem;
  }
  .isHidden-box {
    margin-bottom: 0.36rem;
  }
  .iswant-box {
    margin: 0 0 0.36rem 0.36rem !important;
  }
}
</style>
