<template>
  <div v-if="info && userInfo" class="dfc flex-center">
    <meeting-help-feeling
      isFelling
      :info="info"
      :userInfo="userInfo"
      :type="type + ''"
      class="meeting-box"
    >
      <slot>
        <div class="time-box fs24 fw400 fcb2">{{ time }}</div>
      </slot>
    </meeting-help-feeling>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { timestampFormat } from "../../../utils/util";
export default {
  props: {
    info: Object,
    type: String,
    userInfo: Object
  },
  data() {
    return {
      time: ""
    };
  },
  created() {
    this.time = timestampFormat(dayjs(this.info.created_at).valueOf() / 1000);
  },
  methods: {}
};
</script>

<style lang="less" scoped>
.meeting-box:last-child {
  .time-box {
    margin-right: 0.36rem;
  }
}
</style>
