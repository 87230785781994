<template>
  <div v-if="info && userInfo" class="list-item dfc main-center">
    <meeting-help-info
      :userInfo="userInfo"
      :meetingId="meetingId"
      :info="info"
      :type="type"
      :status="status"
      class="meeting-item"
    >
      <slot>
        <!-- 0=>'我创建的' 1=>'我主持的' 2=>'我参与的'  默认0 type -->
        <div v-if="type == 0">
          <!-- 帮助按钮 -->
          <div class="dfr main-center">
            <div
              class="help-button fs24 fw400 fctheme dfr flex-center"
              v-if="info.is_received == 0"
              @click.capture="isHelp"
            >
              收到帮助
            </div>
          </div>
          <div class="dfr flex-center">
            <div
              class="yet-help-button fs24 fw400 fcccc dfc flex-center"
              v-if="info.is_received == 1"
            >
              已收到帮助
            </div>
          </div>
        </div>
        <div v-if="type == 1 || type == 2">
          <div class="help fs24 fw400 fctheme" v-if="info.is_received == 1">
            案主已收到帮助
          </div>
          <div class="help fs24 fw400 fcb2" v-if="info.is_received == 0">
            待提供帮助
          </div>
        </div>
      </slot>
    </meeting-help-info>
  </div>
</template>

<script>
export default {
  //  <!-- 0=>'我创建的' 1=>'我主持的' 2=>'我参与的'  默认0 type -->
  props: {
    // status  0 => "待付款"  1 => "已取消" 2 => '待召开', 3 => '会议中', 4 => '会后反馈', 5 => '已完成'
    info: Object,
    userInfo: Object,
    type: String,
    meetingId: String,
    status: Number
  },
  data() {
    return {};
  },
  created() {
    this.initPage();
  },
  mounted() {},
  methods: {
    initPage() {},
    // 帮助按钮
    async isHelp() {
      if (this.info.is_received) {
        return;
      }
      const data = {
        id: this.info.id
      };
      const resp = await this.$API.post("confirmHelp", data).then(resp => resp);
      if (resp.code == 1000) {
        this.info.is_received = 1;
      }
    }
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.list-item {
  .help-button {
    width: 1.6rem;
    height: 0.48rem;
    border: 0.01rem solid #cb9025;
    background-color: #ffffff;
    border-radius: 0.3rem;
    margin-right: 0.36rem;
  }
  .yet-help-button {
    height: 0.48rem;
    width: 1.68rem;
    border: 0.01rem solid #cccccc;
    background-color: #ffffff;
    border-radius: 0.3rem;
    margin-right: 0.36rem;
  }
  .help {
    margin-right: 0.36rem;
  }
  .help-buttom-b {
    position: fixed;
    bottom: 0.2rem;
  }
}
</style>
