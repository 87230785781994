<template>
  <div v-if="info" class="list dfc cross-center">
    <div v-if="list.length">
      <div v-for="(item, index) in list" :key="index" class="item">
        <help-card
          :meetingId="id"
          :userInfo="userInfo"
          :info="item"
          :type="type + ''"
          :status="info.status"
        ></help-card>
      </div>
    </div>
    <empty v-else></empty>
    <!-- button -->
    <!-- // 0=>'我创建的' 1=>'我主持的' 2=>'我参与的'  默认0 type -->
    <!-- status 0 => "待付款"  1 => "已取消" 2 => '待召开', 3 => '会议中', 4 => '会后反馈', 5 => '已完成' -->
    <div
      v-if="info.status != 5"
      class="dfr cross-center main-between help-buttom-b"
    >
      <div @click="helpSubmit" v-if="showHelpBtn" class="dfc flex-center">
        <icy-button height="0.8rem" border-radius="0.44rem" :width="btnWidth"
          >{{ isHost && isHelped ?'代私董登记': '登记我的帮助内容'}}</icy-button
        >
        <!-- <icy-iphone></icy-iphone> -->
      </div>
      <!-- <icy-bottom></icy-bottom> -->
      <!-- TODO 按钮展示二维码 -->
      <div
        class="dfc flex-center"
        v-if="type == 1 && info.status != 5"
        @click="openQrcodePopup"
      >
        <icy-button
          height="0.8rem"
          border-radius="0.44rem"
          :width="btnWidth"
          >展示“填写二维码”</icy-button
        >
        <icy-iphone></icy-iphone>
      </div>
    </div>
    <!-- 二维码 -->
    <van-popup
      v-model="qrcodePopupShow"
      :lazy-render="false"
      class="qrcode-popup-wrap"
    >
      <div class="dfc flex-center w100 bgfff qrcode-popup-box">
        <div class="qrcode" ref="qrCodeUrl"></div>
        <div class="fs34 qrcode-popup-title">扫码可以提交帮助给案主</div>
      </div>

      <div class="fs0 qrcode-popup-close" @click="closeQrcodePopup">
        <img src="../../assets/images/popup-close.png" class="w100 h100" />
      </div>
    </van-popup>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import { isApplet } from "../../utils/util";
export default {
  props: {
    id: String,
    type: String,
    info: Object,
    userInfo: Object
  },
  data() {
    return {
      list: [],
      // 是主持人
      isHost: 0,
      isHelped: false,
      qrcode: "",
      // 二维码弹出层
      qrcodePopupShow: false,
      isScan: false
    };
  },
  created() {
    this.routerType = parseInt(this.$route.query.type) || 0;
    this.isScan = parseInt(this.$route.query.scan) == 1 ? true : false;
    this.isHost = this.info.host_user.id === this.userInfo.id ? 1 : 0;
    this.initPage();
  },
  computed: {
    showHelpBtn() {
      return (
        (!this.isHelped &&
          ![2, 5].includes(this.info.status) &&
          [1, 2].includes(parseInt(this.type))) ||
        this.isHost
      );
    },
    btnWidth() {
      return (this.type == 1 &&
        ![2, 5].includes(this.info.status) &&
        !this.isHelped) || this.isHost
        ? "3rem"
        : "6.78rem";
    }
  },
  mounted() {
    this.creatQrCode();
  },
  activated() {},
  methods: {
    // 创建二维码
    creatQrCode() {
      let helpLink =
        location.origin +
        location.pathname +
        `#/meetingDetail?type=2&active=${parseInt(
          this.$route.query.active
        )}&id=${parseInt(this.id)}&scan=1`;
      if (isApplet() && this.$config.appletScanURL) {
        helpLink = `${this.$config.appletScanURL}${encodeURIComponent(
          helpLink
        )}`;
      }
      this.qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: helpLink,
        width: 190,
        height: 190,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H
      });
    },
    async initPage() {
      if (this.isScan) {
        const sidongList = await this.initSidongList();
        const userId = await this.initUserInfo();
        const isJoin = sidongList.some(item => item.user_id == userId);
        if (!isJoin) {
          this.$toast("您目前不是此会议的参会私董，无法操作!");
          this.$go("me");
        }
      }
      this.initList();
    },

    // 获取参会私董
    async initSidongList() {
      let list = [];
      const data = {
        id: this.id
      };
      const resp = await this.$API
        .get("joinMeetingSidongList", data)
        .then(resp => resp);
      if (resp.code == 1000) {
        list = resp.data.list;
      }
      return list;
    },

    // 获取用户
    async initUserInfo() {
      let userId = "";
      const resp = await this.$API.get("userInfo").then(resp => resp);
      if (resp.code == 1000) {
        userId = resp.data.info.id;
      }
      return userId;
    },

    async initList() {
      const data = {
        id: this.id
      };
      const resp = await this.$API.get("helpList", data).then(resp => resp);
      if (resp.code == 1000) {
        let list = resp.data.list;
        if (list.length) {
          let isHelped = list.some(item => item.user.id == this.userInfo.id);
          this.isHelped = isHelped;
        }
        this.list = list;
      }
    },
    helpSubmit() {
      this.$go("addHelp", {
        id: this.id,
        type: this.routerType,
        isHost: this.isHost
      });
    },
    // 打开二维码弹出层
    openQrcodePopup() {
      this.qrcodePopupShow = true;
    },
    // 关闭二维码弹出层
    closeQrcodePopup() {
      this.qrcodePopupShow = false;
    }
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.list {
  min-height: calc(100vh - 44px - 1rem);
  background: #f7f7f7;
  padding-bottom: 1.8rem;
}
.help-buttom-b {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.2rem 0.36rem;
  height: 1.2rem;
  background: #f7f7f7;
}

.qrcode-popup-wrap {
  background: transparent;
  overflow: hidden;
  .qrcode-popup-box {
    border-radius: 0.2rem;
    .qrcode {
      padding: 0.6rem 0.6rem 0.2rem;
    }
    .qrcode-popup-title {
      margin-bottom: 0.6rem;
    }
  }
  .qrcode-popup-close {
    background: transparent;
    width: 0.72rem;
    height: 0.74rem;
    margin: 0 auto;
    margin-top: 0.5rem;
  }
}
</style>
