<template>
  <div class="empty-box">
    <div class="img-box">
      <img class="empty-img" src="../../assets/images/empty.png" alt="" />
    </div>
    <div class="empty-content">{{ content }}</div>
  </div>
</template>

<script>
export default {
  props: { content: { type: String, default: "暂无数据" } },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
  destroyed() {}
};
</script>
<style scoped>
.empty-box {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 1.2rem;
}

.img-box {
  width: 2.6rem;
  height: 2.6rem;
  font-size: 0;
  margin: 176rpx 0 0;
}

.empty-img {
  width: 100%;
  height: 100%;
  display: block;
}

.empty-content {
  color: #999;
  font-size: 0.28rem;
  padding: 0.2rem 0 1rem;
  display: block;
  letter-spacing: 0.02rem;
  margin-top: 0.2rem;
}

.customer .img-box {
  margin: 0;
}
.customer .empty-content {
  padding-bottom: 0;
}
</style>
