<template>
  <div>
    <!-- 0=>'我创建的' 1=>'我主持的' 2=>'我参与的'  默认0 type -->
    <div v-if="type == 0 || type == 2" class="my-list">
      <div
        v-for="item in dataList"
        :key="item.id"
        @click="goDetail(item)"
        class="item"
        :class="[item.path ? 'has-path' : '']"
      >
        <div class="item-left">
          <span class="item-title">{{ item.title }}</span>
        </div>
        <!-- 版本 -->
        <div
          v-if="item.type == 'version'"
          class="fs24 version item-right fc999"
        >
          {{ item.version }}
        </div>
        <div v-else class="item-right">
          <div class="number" v-if="item.number">{{ item.number }}</div>
          <!-- 电话 -->
          <a class="tel fc999 fs24" :href="`tel:${item.tel}`" v-if="item.tel">
            {{ item.tel }}
          </a>
          <div class="arrow-right  sidong-tel">
            <van-icon name="arrow" class="left-icon fc999 fs24" />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="host-box">
        <div
          class="host-item-w dfr main-between cross-center"
          @click="goPerfect"
        >
          <div class="fs28 fw400 fc333">个人信息</div>
          <div class="arrow-right ">
            <van-icon name="arrow" class="left-icon fc999 fs24" />
          </div>
        </div>
        <div
          class="host-item-t dfr main-between cross-center"
          @click="goEveryday"
        >
          <div class="fs24 fw400 fc333">我的可用日程档</div>
          <div class="dfr cross-center">
            <div class="fs24 fw400 fc999">后续30天空余</div>
            <div class="fs24 fw400 fctheme host-time">{{ mounDays }}天</div>
            <div class="arrow-right ">
              <van-icon name="arrow" class="left-icon fc999 fs24" />
            </div>
          </div>
        </div>
      </div>
      <div class="host-box">
        <div class="host-item-w dfr main-between cross-center">
          <div class="fs28 fw400 fc333">联系我们</div>
          <div class="dfr cross-center">
            <a
              class="fs24 fw400 fc999 host-tel"
              :href="`tel:${this.dataList[1].tel}`"
              >{{ this.dataList[1].tel }}</a
            >
            <div class="arrow-right ">
              <van-icon name="arrow" class="left-icon fc999 fs24" />
            </div>
          </div>
        </div>
        <div class="host-item-t dfr main-between cross-center">
          <div class="fs28 fw400 fc333">平台版本</div>
          <div class="dfr cross-center">
            <div class="fs24 fw400 fc999">{{ this.dataList[2].version }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { dataList: Array, type: String, userinfo: Object },
  data() {
    return {
      // 0：代表没有会议 1：代表有会议
      // 第一个值:代表9-12点 第二个值:代表14-17点 第三个值:代表19-21点
      timeData: [],
      currentSelectIndex: NaN,
      currentListSelectIndex: NaN,
      mounDays: 0,
      currentDate: []
    };
  },
  async created() {
    await this.dateMeeting();
  },
  mounted() {},
  methods: {
    // 获取
    // 维护自己档期日期没有的前端三个都关闭  0灰色关闭 1白色开放 2黄色有会议
    async dateMeeting() {
      const resp = await this.$API.get("myDateMeeting").then(resp => resp);
      if (resp.code == 1000) {
        let isArray = Array.isArray(resp.data);
        if (resp.data && !isArray) {
          let busyDays = [];
          Object.keys(resp.data).forEach(item => {
            let hasOpen = resp.data[item].some(item => item == 1);
            if (hasOpen) {
              busyDays.push(item);
            }
          });

          this.mounDays = parseInt(busyDays.length);
        }
      }
    },
    goDetail(item) {
      if (item.tel) {
        return;
      } else {
        if (item.path) {
          this.$go(item.path);
        }
      }
    },
    // 去个人信息修改页面
    goPerfect() {
      this.$go("perfect", { type: this.type });
    },
    // 去日程页面
    goEveryday() {
      this.$go("everyday");
    }
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.my-list {
  width: 100%;
  padding: 0 0.4rem;
  box-sizing: border-box;
}

.my-list .item {
  border-bottom: 0.01rem solid #eee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.32rem 0;
}
.my-list .has-path:active {
  opacity: 0.5;
}

.my-list .item:last-child {
  border: none;
}

.item-left {
  display: flex;
  align-items: center;
}
.number {
  margin-right: 0.1rem;
  line-height: 1;
  font-size: 0.28rem;
}

.item-right {
  margin-right: 0.02rem;
  font-size: 0.24rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tel {
  line-height: 1;
}

.item-left .item-title {
  font-size: 0.28rem;
  color: #333;
  display: block;
}

.item-left .item-icon {
  width: 0.31rem;
  height: 0.36rem;
}

.host-box {
  height: 2.06rem;
  width: 6.94rem;
  margin-top: 0.28rem;
  background: #ffffff;
  box-shadow: 0rem 0.04rem 0.4rem 0 rgba(0, 0, 0, 0.05);
  border-radius: 0.12rem;
}
.host-item-w {
  height: 1rem;
  margin: 0 0.36rem;
  border-bottom: 0.01rem solid #eee;
}

.arrow-right {
  margin-left: 0.04rem;
  display: flex;
  margin-top: 0.02rem;
}
.sidong-tel {
  margin-top: 0;
}
.host-item-t {
  height: 1rem;
  margin: 0 0.36rem;
}
.host-time {
  margin-left: 0.08rem;
}
</style>
