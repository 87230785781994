<template>
  <div v-if="info" class="summary-big">
    <!-- <div class="head-top-bg" /> -->
    <div class="head-box dfr main-between">
      <!-- 头像box -->
      <div class="dfr" @click.stop="goSidongInfo">
        <div v-if="info.user" class="portrait-box">
          <img
            v-if="info.user.avatar"
            :src="info.user.avatar"
            alt=""
            class="w100 h100 object-cover"
          />
          <img
            v-else
            src="../../assets/images/default-portrait.png"
            alt=""
            class="w100 h100"
          />
          <div
            v-if="info && info.user && info.user.is_major_auth"
            class="badge-box"
          >
            <img
              src="../../assets/images/home-emblem.png"
              alt=""
              class="w100 h100"
            />
          </div>
        </div>
        <!-- message -->
        <div v-if="info.user" class="message-box dfc">
          <div v-if="info.user.name" class="fs32 fw600 fc333 dfr cross-center">
            {{ info.user.name }}
            <div v-if="info.user.job" class="my-job fs24 fw400 fc999">
              {{ info.user.job }}
            </div>
          </div>
          <div v-if="info.user.company" class="company fs24 fw400 fc666">
            {{ info.user.company }}
          </div>
          <div
            v-if="info.user.scale || info.user.industry"
            class="scale fs24 fw400 fcb2"
          >
            <span v-if="info.user.industry">{{ info.user.industry.name }}</span>
            <span
              v-if="
                info.user.industry &&
                  info.user.industry.name &&
                  info.user.scale &&
                  info.user.scale.name
              "
              style="margin-right:0.15rem"
            ></span>
            <span v-if="info.user.scale">{{ info.user.scale.name }}</span>
          </div>
        </div>
      </div>
      <!-- 电话按钮 -->
      <div v-if="type == 1">
        <a :href="`tel:${info.user.phone}`" class="phone-box dfr">
          <div class="phone dfc flex-center">
            <img src="../../assets/images/phone.png" alt="" class="w100 h100" />
          </div>
        </a>
      </div>
    </div>
    <!-- head-bottom -->
    <div class="head-bottom dfr main-between">
      <div
        v-if="info.host_user"
        class="dfr cross-center left-box"
        @click.stop="goHostInfo"
      >
        <div class="fs22 fw400 fc999">主持人</div>
        <div class="fs22 fw400 fc666 name-box">{{ info.host_user.name }}</div>
        <div class="name-plate">
          <name-plate :nameplate="info.host_user.host_user_info.level.name" />
        </div>
      </div>
      <div class="dfr cross-center right-box">
        <div class="fs22 fw400 fcb2 time-box">{{ info.hold_datetime }}</div>

        <div
          :class="[info.status == 5 ? 'fc999' : 'fctheme']"
          class="fs26 fw400 fctheme"
        >
          {{ statusMap[info.status] }}
        </div>
      </div>
    </div>

    <!-- meeting-title-box -->
    <div class="meeting-title-box dfc main-between">
      <app-title
        :title="info.is_online <= 1 ? '线下会议' : '线上会议'"
        font-size="0.32rem"
        font-weight="500"
      />

      <div class="dfr main-between cross-center mt20 pr36">
        <!-- is_online 1:线下 2:线上  0 旧会议 默认线下-->
        <div
          class="fs28 fc666"
          style="width:5.78rem; user-select: text;"
          v-if="info.is_online == 2"
        >
          腾讯会议房间号 {{ info.room_no }}
        </div>
        <div
          class="fs28 fc666"
          style="width:5.78rem"
          @click="showMap = true"
          v-else-if="info.is_online == 1"
        >
          <!-- meeting_space_type 1:使用平台推荐的场所 2:使用自己的场所 -->
          <div v-if="info.meeting_space_type == 1">
            {{ info.meeting_space.address }}
          </div>
          <div v-else>
            {{ info.userself_address }}
          </div>
        </div>
        <div
          class="fs28 fc666"
          style="width:5.78rem; user-select: text;"
          v-if="info.is_online == 0"
        >
          自有场所 私董会专用会议室
        </div>
        <van-icon v-if="info.is_online == 1" name="arrow" color="#CDCDCD" />
      </div>
    </div>

    <!-- meeting-title-box -->
    <div class="meeting-title-box dfr main-between">
      <app-title title="会议的讨论议题" font-size="0.32rem" font-weight="500" />
      <!--  // 0=>'我创建的' 1=>'我主持的' 2=>'我参与的'  默认0 type -->
      <!-- 2 => '待召开', 3 => '会议中', 4 => '会后反馈', 5 => '已完成 status -->
      <div v-if="(type == 0 || type == 1) && info.status == 2">
        <div @click="goeDitor" class="dfr flex-center">
          <div class="editor-img-box dfr ">
            <img
              src="../../assets/images/editor.png"
              alt=""
              class="w100 h100"
            />
          </div>
          <div class="fs28 fw400 fc999 editor">编辑</div>
        </div>
      </div>
    </div>

    <!-- #  # -->
    <div v-if="info.field" class="fs28 fw400 fctheme field-box">
      # {{ info.field.name }} #
    </div>

    <!-- 问答 -->
    <div class="answer-box ">
      <div class="ask dfr cross-center">
        <img src="../../assets/images/shape.png" alt="" />
        <span class="fs28 fb fc333">我的困惑是，我如何</span>
      </div>
      <div class="reply fs28 fw400 fc666">
        {{ info.questions[0] }}
      </div>

      <div class="ask ask-reuse dfr cross-center">
        <img src="../../assets/images/shape.png" alt="" />
        <span class="fs28 fb fc333">这个问题是重要的，因为</span>
      </div>
      <div class="reply fs28 fw400 fc666">
        {{ info.questions[1] }}
      </div>

      <div class="ask ask-reuse dfr cross-center">
        <img src="../../assets/images/shape.png" alt="" />
        <span class="fs28 fb fc333"
          >到目前为止，为了解决这个问题我已经做了</span
        >
      </div>
      <div class="reply fs28 fw400 fc666">
        {{ info.questions[2] }}
      </div>

      <div class="ask ask-reuse dfr cross-center">
        <img src="../../assets/images/shape.png" alt="" />
        <span class="fs28 fb fc333">我希望小组帮助我的是</span>
      </div>
      <div class="reply fs28 fw400 fc666">
        {{ info.questions[3] }}
      </div>
    </div>

    <!-- print-title-box -->
    <div v-if="info.files.length">
      <div class="print-title-box">
        <app-title title="图片附件" font-size="0.32rem" font-weight="500" />
      </div>
      <div class="picture-img-box dfr flex-wrap ">
        <div
          class="picture-img"
          v-for="(item, index) in info.files"
          :key="index"
          @click="previewImg(index)"
        >
          <img v-if="item.url" :src="item.url | addImgFix" alt="" />
          <img v-else :src="item | addImgFix" alt="" />
        </div>
      </div>
    </div>
    <!--  // 0=>'我创建的' 1=>'我主持的' 2=>'我参与的'  默认0 type -->
    <!-- 2 => '待召开', 3 => '会议中', 4 => '会后反馈', 5 => '已完成 -->
    <!-- 只有进行中的会议和角色为主持人才可关闭会议 -->
    <div v-if="type == 1 && info.status == 3" class="b-button-box">
      <div class="my-bottom-button dfr flex-center" @click="closeMeeting">
        <icy-button height="0.8rem">关闭会议</icy-button>
      </div>
    </div>

    <!-- 地图展示 -->
    <van-popup v-model="showMap" position="bottom" round>
      <div class="map-wrap">
        <baidu-map
          v-if="center"
          class="w100 h100"
          :center="center"
          :zoom="zoom"
        >
          <bm-marker
            :position="center"
            :dragging="true"
            @click="infoWindowOpen"
            animation="BMAP_ANIMATION_BOUNCE"
          >
            <bm-info-window
              :show="addressShow"
              @close="infoWindowClose"
              @open="infoWindowOpen"
              >{{ info.meeting_space.name }} <br />
              {{ info.meeting_space.address }}</bm-info-window
            >
          </bm-marker>
        </baidu-map>
        <empty v-else content="暂不支持自定义地址显示"></empty>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
export default {
  props: {
    type: String,
    id: String,
    info: Object,
    userInfo: Object
  },
  data() {
    return {
      statusMap: ["", "", "待召开", "会议中", "会后反馈", "已完成"],
      picture: [],
      invite: "",
      // 地图
      showMap: false,
      addressShow: true,
      center: null,
      zoom: 18
    };
  },
  created() {
    this.invite = parseInt(this.$route.query.invite) || "";
    this.initPage();
  },
  mounted() {},
  methods: {
    previewImg(index) {
      let images = [];
      this.info.files.forEach(item => {
        images.push(this.$addImgFix(item));
      });
      ImagePreview({
        images: images,
        startPosition: index
      });
    },
    initPage() {
      let lnglat = null;
      if (
        this.info &&
        this.info.meeting_space &&
        this.info.meeting_space.longitude &&
        this.info.meeting_space.latitude
      ) {
        lnglat = {
          lng: this.info.meeting_space.longitude,
          lat: this.info.meeting_space.latitude
        };
      }
      this.center = lnglat;
    },
    // 关闭会议
    closeMeeting() {
      this.$dialog
        .confirm({
          message: "确认关闭此会议吗?"
        })
        .then(() => {
          this.meetingEnd();
          this.$toast.success("关闭成功");
          this.$router.replace("/me");
        })
        .catch(() => {
          // on cancel
        });
    },
    async meetingEnd() {
      await this.$API.get("meetingEnd", { id: this.id });
    },
    // 去编辑页面
    goeDitor() {
      this.$router.push({ path: "/editorMeeting", query: { id: this.id } });
    },
    goSidongInfo() {
      this.$go("detailS", { id: this.info.user.id });
    },
    goHostInfo() {
      this.$go("detail", { id: this.info.host_user.id });
    },
    mapHandler() {},
    infoWindowClose() {
      this.addressShow = false;
    },
    infoWindowOpen() {
      this.addressShow = true;
    }
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.my-job {
  margin-left: 0.16rem;
}
.company {
  margin-top: 0.08rem;
}
.scale {
  margin-top: 0.04rem;
}
.summary-big {
  .head-top-bg {
    height: 0.2rem;
    background-color: #f6f6f6;
  }
  .head-box {
    height: 2.02rem;
    border-bottom: 0.02rem solid #f7f7f7;
    .portrait-box {
      width: 0.96rem;
      height: 0.96rem;
      margin: 0.36rem 0 0 0.36rem;
      position: relative;
      img {
        border-radius: 50%;
        object-fit: cover;
      }
      .badge-box {
        width: 0.28rem;
        height: 0.28rem;
        position: absolute;
        right: 0;
        bottom: 0;
        img {
          position: absolute;
          object-fit: cover;
        }
      }
    }
    .message-box {
      margin-top: 0.42rem;
      margin-left: 0.2rem;
    }
    .post-box {
      margin-top: 0.48rem;
      margin-left: 0.16rem;
    }
    .phone-box {
      width: 0.64rem;
      height: 0.64rem;
      background-color: #fef8ee;
      border-radius: 50%;
      margin-top: 0.52rem;
      margin-right: 0.36rem;
      .phone {
        width: 0.2594rem;
        height: 0.32rem;
        margin-top: 0.16rem;
        margin-left: 0.19rem;
      }
    }
  }
  .head-bottom {
    height: 1rem;
    border-bottom: 0.2rem solid #f7f7f7;
    .left-box {
      margin-left: 0.36rem;
      .name-box {
        margin-left: 0.16rem;
        max-width: 4rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .name-plate {
        margin-left: 0.12rem;
      }
    }
    .right-box {
      margin-right: 0.31rem;
      .time-box {
        margin-top: 0.05rem;
        margin-right: 0.12rem;
      }
    }
  }
  .meeting-title-box {
    margin-left: 0.36rem;
    margin-top: 0.45rem;
    .editor-img-box {
      width: 0.28rem;
      height: 0.28rem;
    }
    .editor {
      margin-right: 0.36rem;
      margin-left: 0.16rem;
    }
  }
  .field-box {
    margin-left: 0.36rem;
    margin-top: 0.16rem;
  }
  .answer-box {
    margin: 0.36rem 0.36rem 0 0.36rem;
    .ask {
      img {
        width: 0.12rem;
        height: 0.12rem;
        margin-right: 0.16rem;
      }
    }
    .ask-reuse {
      margin-top: 0.48rem;
    }
    .reply {
      margin-top: 0.16rem;
      margin-left: 0.28rem;
    }
  }
  .print-title-box {
    margin: 0.8rem 0 0.36rem 0.36rem;
  }
  .picture-img-box {
    margin: 0.36rem 0.36rem 1.3rem 0.36rem;
    .picture-img {
      width: 2.1rem;
      height: 2.1rem;
      margin: 0 0.22rem 0.2rem 0;
      &:nth-child(3n) {
        margin-right: 0;
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 0.08rem;
        object-fit: cover;
      }
    }
  }
  .b-button-box {
    height: calc(1.2rem + env(safe-area-inset-bottom));
    height: calc(1.2rem + constant(safe-area-inset-bottom));
    .my-bottom-button {
      padding: 0.2rem 0.36rem;
      padding-bottom: calc(0.2rem + env(safe-area-inset-bottom));
      padding-bottom: calc(0.2rem + constant(safe-area-inset-bottom));
      width: 7.5rem;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}
.map-wrap {
  height: 90vh;
  width: 100vw;
}
</style>
