<template>
  <div>
    <!-- 全部 -->
    <div @click="goDetail(info)" class="list-item dfr main-center">
      <div class="meeting-box">
        <square-hade
          :info="info"
          :status="info.status + ''"
          :type="type + ''"
        />
        <div class="text-box fs28 fw400 fc666">
          我的困惑是如何「{{ info.questions[0] }}」这对「{{
            info.questions[1]
          }}」很重要，我针对这个问题已做了「{{
            info.questions[2]
          }}」，我希望帮我「{{ info.questions[3] }}」
        </div>
        <div>
          <square-host-bottom
            :info="info"
            :status="info.status + ''"
            :type="type + ''"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // status 0 => "待付款"  1 => "已取消" 2 => '待召开', 3 => '会议中', 4 => '会后反馈', 5 => '已完成'
  // type 0=>'我创建的' 1=>'我主持的' 2=>'我参与的'  默认0
  props: {
    info: Object,
    type: String,
    status: String
  },
  data() {
    return {};
  },
  created() {
    this.initPage();
  },
  mounted() {},
  methods: {
    initPage() {},
    goDetail(info) {
      if (info.status == 0) {
        this.$go("meetingPaid", {
          meetingId: info.id
        });
      } else {
        this.$go("meetingDetail", {
          id: info.id,
          type: this.type,
          status: info.status
        });
      }
      // this.$bus.$emit("goMeetingDetail", { id: info.id, type: this.type });
    }
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.meeting-box {
  width: 6.94rem;
  background: #ffffff;
  border-radius: 0.12rem;
  margin-top: 0.28rem;
  padding: 0.36rem 0.36rem 0;
  .text-box {
    margin-top: 0.4rem;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
}
</style>
