<template>
  <div
    class="namePlate  "
    :class="[isBig ? 'big' : '']"
    :style="`background: ${background};font-size: ${fontSize}`"
  >
    {{ nameplate }}
  </div>
</template>

<script>
export default {
  props: {
    isBig: {
      type: Boolean,
      default: false
    },
    nameplate: String,
    background: {
      type: String,
      default: "linear-gradient(270deg, #d3a96e 0%, #e8caa6 100%)"
    },
    fontSize: {
      type: String,
      default: "0.2rem"
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="less" scoped>
.namePlate {
  border-radius: 0.06rem 0.18rem 0.06rem 0.18rem;
  color: #ffffff;
  transform: scale(0.77);
  transform-origin: left center;
  background: linear-gradient(270deg, #d3a96e 0%, #e8caa6 100%);
  padding: 0.06rem 0.12rem;
  font-weight: 500;
  &.big {
    transform: scale(0.9) translateY(0.01rem);
  }
}
</style>
