<template>
  <div class="compereBox bgfff" @click="goHostInfo(info.id)">
    <div class="dfc flex-center">
      <div class="portraitBox por">
        <img
          v-if="info.avatar"
          class="portraitImage object-cover"
          :src="info.avatar"
        />
        <img
          v-else
          src="../../assets/images/default-portrait.png"
          alt=""
          class="portraitImage object-cover"
        />
        <div class="emblemBox fs0" v-if="info.is_auth">
          <img
            class="emblemImage"
            src="../../assets/images/home-emblem.png"
            mode="aspectFill"
          />
        </div>
      </div>
      <div class="name fs32 fc333 fb">
        {{ info.name }}
      </div>
      <div class="direct dfr cross-center">
        <span class=" fs24 fc666">主持场数</span>
        <span class="count fs28">
          {{ info.total_count }}
        </span>
      </div>
      <div class="emblemBottom fs24 fc999">
        {{ info.fields }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: Object
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    goHostInfo(id) {
      this.$router.push({ path: "/detail", query: { id: id } });
    }
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.compereBox {
  width: 2.18rem;
  min-height: 3.92rem;
  border-radius: 0.12rem;
  box-shadow: 0px 0.04rem 0.4rem 0px rgba(0, 0, 0, 0.05);
  .portraitBox {
    width: 1.2rem;
    height: 1.2rem;
    margin-top: 0.4rem;
    .portraitImage {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
    .emblemBox {
      width: 0.32rem;
      height: 0.32rem;
      bottom: 0;
      right: 0;
      position: absolute;
      .emblemImage {
        width: 100%;
        height: 100%;
      }
    }
  }
  .name {
    margin-top: 0.2rem;
  }
  .direct {
    margin-top: 0.08rem;
    margin-bottom: 0.18rem;
  }

  .count {
    color: #cb9025;
    display: inline-block;
    margin-left: 0.08rem;
  }

  .emblemBottom {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 1.78rem;
    margin-bottom: 0.4rem;
    text-align: center;
  }
}
</style>
