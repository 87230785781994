<template>
  <div v-if="info" class="feeling-Info">
    <!-- <icy-header title="感想"></icy-header> -->
    <div class="dfc cross-center">
      <div class="square-hade">
        <div class="dfr cross-center main-between" @click="goSidongInfo">
          <div class="dfr">
            <!-- 头像 -->
            <div class="portrait-box">
              <img
                v-if="info.user && info.user.avatar"
                :src="info.user.avatar"
                class="portrait-image w100 h100 object-cover"
                alt=""
              /><img
                v-else
                src="../../assets/images/default-portrait.png"
                class="portrait-image w100 h100"
                alt=""
              />
              <div v-if="info.user && info.user.is_auth" class="badge-box">
                <img
                  src="../../assets/images/home-emblem.png"
                  class="w100 h100"
                  alt=""
                />
              </div>
            </div>

            <!-- 信息 -->
            <div
              v-if="info.user"
              class="message-box dfc flex-center cross-start"
            >
              <div class="name-box fs32 fw600 fc333">{{ info.user.name }}</div>
              <!-- {{ info.user.job }} -->
              <div class="fs24 fc999 fw400">{{ info.user.job || "" }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="hr" />

      <div v-if="info" class="bootm-box dfr main-between cross-center">
        <div class="dfr cross-center" @click="goHostInfo">
          <div class="fs24 fc999 dfr">推荐人</div>
          <div v-if="info.share_user" class="referrer fs24 fc666">
            {{ info.share_user.name }}
          </div>
          <div v-if="info.share_user" class="name-plate-box fcfff">
            <name-plate
              :nameplate="info.share_user.host_user_info.level.name"
            ></name-plate>
          </div>
        </div>

        <div
          v-if="info.meeting && info.meeting.field && info.meeting.field.name"
          class="session fctheme fs24 fw400"
        >
          # {{ info.meeting.field.name }} #
        </div>
      </div>

      <div class="hr-bottom" />
    </div>

    <div class="info-title dfr mian-start">
      <app-title title="感想" />
    </div>
    <!-- 感想内容 -->
    <div class="text-box fs28 fw400 fc666 dfr main-start">
      {{ info.content }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: {}
    };
  },
  created() {
    this.squareDetail();
  },
  methods: {
    async squareDetail() {
      const resp = await this.$API
        .get("squareDetail", { id: this.$route.query.id }, false)
        .then(resp => resp);
      if (resp.code == 1000) {
        this.info = resp.data;
      }
    },
    goSidongInfo() {
      let targetPath = "detail";
      if (!this.info.user.type) {
        targetPath = "detailS";
      }
      this.$go(targetPath, { id: this.info.user.id });
    },
    goHostInfo() {
      this.$go("detail", { id: this.info.share_user.id });
    }
  }
};
</script>

<style lang="less" scoped>
.feeling-Info {
  width: 7.5rem;
  .square-hade {
    width: 6.8rem;
    margin-top: 0.26rem;
    .portrait-box {
      width: 0.96rem;
      height: 0.96rem;
      position: relative;
      img {
        object-fit: cover;
      }
      .portrait-image {
        border-radius: 50%;
      }
      .badge-box {
        width: 0.28rem;
        height: 0.28rem;
        top: 0;
        right: 0;
        position: absolute;
      }
    }
    .message-box {
      margin-left: 0.2rem;
      .name-box {
        margin-bottom: 0.06rem;
      }
    }
    .time-box {
      margin-top: 0.04rem;
    }
  }
  .hr {
    width: 100%;
    border-bottom: 0.01rem solid #f7f7f7;
    margin-top: 0.4rem;
    margin-bottom: 0.34rem;
  }
  .bootm-box {
    width: 100%;
    padding: 0 0.36rem;
    .referrer {
      margin-left: 0.16rem;
    }
    .name-plate-box {
      margin-left: 0.12rem;
    }
  }
  .hr-bottom {
    width: 100%;
    margin-top: 0.2rem;
    border-bottom: 0.2rem solid #f7f7f7;
  }
  .info-title {
    margin-left: 0.36rem;
    margin-top: 0.46rem;
  }
  .text-box {
    width: 6.8rem;
    margin: 0.28rem 0.36rem 0 0.36rem;
  }
}
</style>
