<template>
  <div>
    <!-- 具体内容 -->
    <van-list
      :offset="200"
      v-model="loading"
      loading-text=""
      :finished="finished"
      v-if="flowList.length"
      @load="onLoad"
    >
      <div class="flow-box">
        <div v-for="item in flowList" :key="item.id" class="flow-top-max-wrap">
          <div class="inner">
            <div class="flow-top-wrap dfr cross-center main-between">
              <div class="name fs30 fc333">
                <!-- ['佣金获得', '提现扣除', '提现失败退回'] -->
                <!-- 1私董充值  2私董参会增加  3私董开会扣减  4主持人参会获得  5提现扣减 -->
                <span>{{ types[item.type] }}</span>
                <span v-if="item.remark">（{{ item.remark }}）</span>
              </div>
              <div class="flow-price-box fc333 dfr cross-center ">
                <span v-if="item.change_type == 1" class="unit-left fw500 fs36"
                  >-</span
                >
                <span v-else class="unit-left fw500 fs36">+</span>
                <span class="flow-price fs40 ">{{ item.change_amount }}</span>
              </div>
            </div>
            <div class="time-f fs22 fc999">{{ item.time }}</div>
          </div>
        </div>
      </div>
    </van-list>
    <empty v-else class="empty" content="暂无数据"></empty>
  </div>
</template>

<script>
import { timestampFormat } from "../../utils/util";
import dayjs from "dayjs";
export default {
  props: {
    type: String
  },
  data() {
    return {
      types: [
        "签到",
        "提交个人参会感想",
        "点选特别好感参会者",
        "确认是否完成改进",
        "案主确认收到帮助",
        "支付会议费用",
        "提现到银行账户",
        "提现失败退回",
        "充值",
        "后台调整",
        "主持会议获得"
      ],
      isListLoading: true,
      flowList: [],
      page: 1,
      finished: false,
      loading: false,
      pageSize: 10,
      total: 0
    };
  },
  async created() {
    this.initPage();
  },
  mounted() {},
  methods: {
    async initPage() {
      this.page = 1;
      this.flowList = await this.initList();

      if (this.total < this.pageSize) {
        this.loading = false;
        this.finished = true;
      } else {
        this.loading = false;
        this.finished = false;
      }
      this.isListLoading = false;
    },
    async initList() {
      let list = [];
      const data = {
        type: this.type,
        page: this.page,
        pageSize: this.pageSize
      };
      const resp = await this.$API
        .post("flowRecordList", data)
        .then(resp => resp);
      if (resp.code == 1000) {
        this.total = resp.data.total;
        list = resp.data.list;
        list.forEach(item => {
          item.time = timestampFormat(dayjs(item.created_at).valueOf() / 1000);
        });
      }
      return list;
    },
    async onLoad() {
      if (!this.finished) {
        this.page++;
        const flowList = await this.initList();

        if (flowList.length < this.pageSize) {
          this.flowList = [...this.flowList, ...flowList];
          this.loading = false;
          this.finished = true;
        } else {
          this.flowList = [...this.flowList, ...flowList];
          this.loading = false;
          this.finished = false;
        }
      }
    }
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.flow-top-max-wrap {
  padding: 0.28rem 0.36rem 0;
  .inner {
    border-bottom: 0.01rem solid #eee;
    padding-bottom: 0.2rem;
  }
}
.time-f {
  margin-top: 0.08rem;
}
.flow-box .time {
  padding: 0.18rem 0 0.28rem;
  border-bottom: 0.01rem solid #eee;
}
.flow-top-max-wrap:last-child .time {
  border: none;
}
.flow-top-wrap {
  display: flex;
}
.flow-box {
  border-bottom: 0.01rem solid #eee;
  background: #fff;
}
.flow-box .name {
  width: 4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transform: translateY(0.05rem);
}
.flow-price {
  display: inline-block;
  transform: translateY(0.03rem);
}
</style>
