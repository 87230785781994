<template>
  <van-tabbar
    safe-area-inset-bottom
    placeholder
    fixed
    active-color="#B5B1B2"
    inactive-color="#B5B1B2"
    v-model="showNum"
  >
    <van-tabbar-item to="/home" :replace="isReplace">
      <span :class="[active == 0 ? 'active' : '', 'text']">首页</span>
      <img slot="icon" :src="active == 0 ? home.active : home.normal" />
    </van-tabbar-item>
    <van-tabbar-item to="/square" :replace="isReplace">
      <span :class="[active == 1 ? 'active' : '', 'text']">广场</span>
      <img slot="icon" :src="active == 1 ? history.active : history.normal" />
    </van-tabbar-item>
    <!-- <van-tabbar-item to="/resources" :replace="isReplace">
      <span :class="[active == 2 ? 'active' : '', 'text']">热链</span>
      <img
        slot="icon"
        :src="active == 2 ? resources.active : resources.normal"
      />
    </van-tabbar-item> -->
    <van-tabbar-item to="/me" :replace="isReplace">
      <span :class="[active == 3 ? 'active' : '', 'text']">我的</span>
      <img slot="icon" :src="active == 3 ? me.active : me.normal" />
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  props: {
    active: String
  },
  data() {
    return {
      showNum: "",
      isVisible: true,
      home: {
        normal: require("../assets/images/home-default.png"),
        active: require("../assets/images/home-active.png")
      },
      history: {
        normal: require("../assets/images/square-default.png"),
        active: require("../assets/images/square-active.png")
      },
      resources: {
        normal: require("../assets/images/link-default.png"),
        active: require("../assets/images/link-active.png")
      },
      me: {
        normal: require("../assets/images/me-default.png"),
        active: require("../assets/images/me-active.png")
      },

      isReplace: true
    };
  },
  created() {},

  mounted() {}
};
</script>

<style lang="less" scoped>
.text.active {
  color: #333;
}
</style>
