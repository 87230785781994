<template>
  <button
    class="button dfr cross-center"
    :disabled="disabled"
    @click="clickHandle"
    :class="[
      left ? 'main-start' : '',
      right ? 'main-end' : '',
      center ? 'main-center' : ''
    ]"
    :style="
      `border:${border};width:${width}; height:${height};border-radius: ${borderRadius}; background: ${
        disabled ? disabledBackground : background
      };font-size:${fontSize};color:${disabled ? disabledColor : color};`
    "
  >
    <slot></slot>
  </button>
</template>

<script>
import { debounce } from "../../utils/util";
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    disabledBackground: {
      type: String,
      default: "#E0E0E0"
    },
    disabledColor: {
      type: String,
      default: "#FFFFFF"
    },
    left: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    },
    center: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: "6.78rem"
    },
    height: {
      type: String,
      default: ".88rem"
    },
    borderRadius: {
      type: String,
      default: ".44rem"
    },
    background: {
      type: String,
      default: "linear-gradient(270deg, #E3B06B 0%, #FDDC98 100%)"
    },
    boxshadow: {
      type: String,
      default: "0 .04rem .12rem 0 rgba(227, 176, 107, 0.3)"
    },
    fontSize: {
      type: String,
      default: ".28rem"
    },
    color: {
      type: String,
      default: "#7C5A2F"
    },
    border: {
      type: String,
      default: "none"
    }
  },
  data() {
    return {
      clickTimer: null,
      canClick: true
    };
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    clickHandle: debounce(function() {
      this.$emit("click");
    }, 200)
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.button {
  transition: all 0.5s;
  padding: 0 0.3rem;
  &:active {
    opacity: 0.8;
  }
}
</style>
