import Vue from "vue";

import {
  Tab,
  List,
  DatetimePicker,
  DropdownMenu,
  DropdownItem,
  Tabs,
  Tabbar,
  TabbarItem,
  Toast,
  Dialog,
  Icon,
  Field,
  Popup,
  Loading,
  Button,
  Swipe,
  NoticeBar,
  SwipeItem,
  Badge,
  NavBar,
  Lazyload,
  Divider,
  Picker,
  Form,
  Uploader,
  Checkbox,
  ActionSheet,
  Search
} from "vant";
Vue.use(Toast)
  .use(List)
  .use(Tab)
  .use(DatetimePicker)
  .use(ActionSheet)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(Tabs)
  .use(Tabbar)
  .use(TabbarItem)
  .use(Icon)
  .use(Button)
  .use(NoticeBar)
  .use(Dialog)
  .use(Field)
  .use(Popup)
  .use(NavBar)
  .use(Loading)
  .use(Swipe)
  .use(SwipeItem)
  .use(Lazyload)
  .use(Badge)
  .use(Divider)
  .use(Picker)
  .use(Form)
  .use(Uploader)
  .use(Checkbox)
  .use(Search);
