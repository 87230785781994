<template>
  <div class="my-meeting">
    <div class="title dfr  main-between">
      <div class="t-left dfr cross-center fs28 fc333 fw500">{{ title }}</div>
      <router-link
        :to="`/myMeeting?type=${type}`"
        class=" dfr cross-center fs24 fc999"
      >
        查看全部 <van-icon name="arrow" class="left-icon" />
      </router-link>
    </div>
    <div class="content dfr cross-center main-between fs24 fc666">
      <router-link
        :to="`/myMeeting?type=${type}&active=1`"
        class="content-f dfc flex-center"
      >
        <van-badge
          color="#FF4545"
          :content="waitNum"
          v-if="waitNum > 0 ? true : false"
        >
          <div class="icon-box">
            <img
              src="../../assets/images/me-meeting-01.png"
              alt=""
              class="icon w100 h100"
            />
          </div>
        </van-badge>
        <div class="icon-box" v-else>
          <img
            src="../../assets/images/me-meeting-01.png"
            alt=""
            class="icon w100 h100"
          />
        </div>
        待召开
      </router-link>
      <router-link
        :to="`/myMeeting?type=${type}&active=2&num=${num}`"
        class="content-f dfc flex-center"
      >
        <van-badge :content="num" v-if="num > 0 ? true : false">
          <div class="icon-box">
            <img
              src="../../assets/images/me-meeting-02.png"
              alt=""
              class="icon w100 h100"
            />
          </div>
        </van-badge>
        <div class="icon-box" v-else>
          <img
            src="../../assets/images/me-meeting-02.png"
            alt=""
            class="icon w100 h100"
          />
        </div>
        进行中
      </router-link>
      <router-link
        :to="`/myMeeting?type=${type}&active=3`"
        class="content-f dfc flex-center"
      >
        <div class="icon-box">
          <img
            src="../../assets/images/me-meeting-03.png"
            alt=""
            class="icon w100 h100"
          />
        </div>
        已完成
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    num: String,
    type: String,
    waitNum: String
  },
  data() {
    return {};
  }
};
</script>
<style lang="less" scoped>
.my-meeting {
  height: 2.84rem;
  width: 6.94rem;
  margin: 0 auto 0.28rem;
  background: #ffffff;
  box-shadow: 0rem 0.04rem 0.4rem 0 rgba(0, 0, 0, 0.05);
  border-radius: 0.12rem;
  .title {
    height: 0.88rem;
    border-bottom: 0.01rem solid #eeeeee;
    .t-left {
      margin-left: 0.36rem;
    }
    .left-icon {
      margin-right: 0.36rem;
      margin-left: 0.06rem;
    }
  }
  .content {
    padding: 0 0.8rem;
    .content-f {
      margin-top: 0.62rem;
      .icon-box {
        width: 0.52rem;
        height: 0.52rem;
        position: relative;
        margin-bottom: 0.12rem;
      }
    }
  }
}
</style>
