<template>
  <div v-if="info" class="head-box">
    <div class="dfr cross-center main-between">
      <div class="dfr" @click.stop="goSidongInfo">
        <!-- 头像 -->
        <div class="portrait-box">
          <img
            v-if="info.user && info.user.avatar"
            :src="info.user.avatar"
            class="portrait-image w100 h100 object-cover"
            alt=""
          /><img
            v-else
            src="../../assets/images/default-portrait.png"
            class="portrait-image w100 h100"
            alt=""
          />
          <div v-if="info.user.is_major_auth" class="badge-box">
            <img
              src="../../assets/images/home-emblem.png"
              class="w100 h100"
              alt=""
            />
          </div>
        </div>

        <!-- 信息 -->
        <div v-if="info.user" class="message-box dfc flex-center cross-start">
          <div class="name-box fs32 fw600 fc333">{{ info.user.name }}</div>
          <!-- {{ info.user.job }} -->
          <div class="fs24 fc999 fw400">{{ info.user.job || "" }}</div>
        </div>
      </div>
      <!-- 0 => "待付款"  1 => "已取消" 2 => '待召开', 3 => '会议中', 4 => '会后反馈', 5 => '已完成'
      //type 0=>'我创建的' 1=>'我主持的' 2=>'我参与的'  默认0 -->
      <div>
        <div
          :class="[
            info.status == 5 || info.status == 1 ? 'fc999' : 'fctheme',
            info.status == 0 ? 'err-color' : ''
          ]"
          class="status-text fs28 text-right"
        >
          {{ statusMap[info.status] }}
        </div>
        <div class="status-time fs24 fc999">
          {{ info.holdDatetime }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  props: {
    info: Object,
    status: String,
    // type 0=>'我创建的' 1=>'我主持的' 2=>'我参与的'  默认0
    type: String
  },
  data() {
    return {
      // 0 => "待付款"  1 => "已取消" 2 => '待召开', 3 => '会议中', 4 => '会后反馈', 5 => '已完成'
      statusMap: ["待付款", "已取消", "待召开", "会议中", "会后反馈", "已完成"],
      currentInfo: null
    };
  },
  watch: {
    info(_info) {
      this.initInfo(_info);
    }
  },
  created() {
    this.initInfo(this.info);
  },
  methods: {
    initInfo(info) {
      info.holdDatetime = dayjs(info.hold_datetime).format("YYYY-MM-DD HH:mm");
      this.currentInfo = info;
    },
    goSidongInfo() {
      this.$go("detailS", { id: this.info.user.id });
    }
  }
};
</script>

<style lang="less" scoped>
.status-time {
  margin-top: 0.08rem;
}
.status-text.err-color {
  color: #ff4545;
}
.head-box {
  width: 100%;
  position: relative;
  .portrait-box {
    width: 0.96rem;
    height: 0.96rem;
    position: relative;
    .portrait-image {
      border-radius: 50%;
    }
    .badge-box {
      width: 0.28rem;
      height: 0.28rem;
      bottom: 0;
      right: 0;
      position: absolute;
    }
    img {
      object-fit: cover;
    }
  }
  .message-box {
    margin-left: 0.2rem;
    .name-box {
      margin-bottom: 0.06rem;
    }
  }
  .time-box {
    margin-top: 0.04rem;
  }
}
</style>
