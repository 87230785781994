<template>
  <div v-if="info" class="bootm-box dfr main-between">
    <div class="dfr cross-center">
      <div class="fs24 fc999 dfr">推荐人</div>
      <div v-if="info.share_user" class="referrer fs24 fc666">
        {{ info.share_user.name }}
      </div>
      <div v-if="info.share_user" class="name-plate-box fcfff">
        <name-plate
          :nameplate="info.share_user.host_user_info.level.name"
        ></name-plate>
      </div>
    </div>

    <div
      v-if="info.meeting && info.meeting.field && info.meeting.field.name"
      class="session fctheme fs24 fw400"
    >
      # {{ info.meeting.field.name }} #
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: Object
  },
  data() {
    return {};
  }
};
</script>

<style lang="less" scoped>
.bootm-box {
  width: 100%;
  position: relative;
  .referrer {
    margin-left: 0.16rem;
  }
  .name-plate-box {
    margin-left: 0.12rem;
  }
}
</style>
