<template>
  <div class="sidong-card dfc cross-center" @click="goHostInfo(info.id)">
    <!-- 头像 -->
    <div class="portrait-box">
      <img
        v-if="info.avatar"
        :src="info.avatar"
        alt=""
        class="w100 h100 object-cover"
      />
      <img
        v-else
        src="../../assets/images/default-portrait.png"
        alt=""
        class="w100 h100 object-cover"
      />
      <div v-if="info.is_major_auth" class="emblem-box">
        <img
          src="../../assets/images/home-emblem.png"
          alt=""
          class="w100 h100"
        />
      </div>
    </div>
    <!-- 姓名 -->
    <div class="name-box fs32 fw600 fc333">
      {{ info.name }}
    </div>

    <!-- 职位 -->
    <div class="direct-box fs24 fw400 fc666">
      {{ info.job }}
    </div>

    <!-- 描述 -->
    <div class="dfc flex-center">
      <div class="emblem-bottom fs24 fw400 fc999 dfr mian-center">
        {{ info.fields }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: Object
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    goHostInfo(id) {
      this.$router.push({ path: "/detailS", query: { id: id } });
    }
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.sidong-card {
  width: 3rem;
  height: 3.86rem;
  background: #ffffff;
  box-shadow: 0 0.04rem 0.4rem 0 rgba(0, 0, 0, 0.05);
  border-radius: 0.12rem;
  .portrait-box {
    width: 1.2rem;
    height: 1.2rem;
    margin-top: 0.4rem;
    position: relative;
    img {
      border-radius: 50%;
    }
    .emblem-box {
      width: 0.32rem;
      height: 0.32rem;
      bottom: 0;
      right: 0;
      position: absolute;
      img {
        position: absolute;
      }
    }
  }
  .name-box {
    margin-top: 0.2rem;
  }
  .direct-box {
    margin-top: 0.04rem;
  }
  .emblem-bottom {
    width: 2.6rem;
    margin-bottom: 0.4rem;
    margin-top: 0.16rem;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: center;
  }
}
</style>
